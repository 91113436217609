import styled from 'styled-components';

export const CommunityPageText = styled.div`
  width: auto;
  max-width: 100%;
`;

export const TextButton = styled.div`
  margin-top: 20px;
`;

export const TextHtmlButton = styled.a<{ bgColor: string }>`
  display: inline-block;
  position: relative;
  min-width: 40px;
  padding: 8px 16px;
  background-color: ${({ bgColor }) => `#${bgColor}`};
  background-image: linear-gradient(90deg, ${({ bgColor }) => `#${bgColor}`}, rgba(255, 255, 255, 0.18));
  border: 0 solid transparent;
  border-radius: 18px;
  cursor: pointer;
  text-align: center;
  font:
    700 14px 'Nunito',
    -apple-system,
    BlinkMacSystemFont,
    Helvetica,
    Arial,
    sans-serif;
  color: #fff;
  line-height: 1.43;
  transition: background 0.3s ease-in-out;
  word-break: keep-all;

  &:hover {
    background-image: linear-gradient(90deg, ${({ bgColor }) => `#${bgColor}`}, rgba(0, 0, 0, 0));
    text-decoration: none;
  }
`;
