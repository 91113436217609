import styled from 'styled-components';
import { type IPageItemSettings } from '../../../../common/interfaces';

export const Img = styled.img<{ $settings: IPageItemSettings }>`
  width: ${(props) => (props.$settings?.imageWidth ? ''.concat(props.$settings?.imageWidth, 'px') : 'auto')};
  height: 'auto';
  border-radius: ${(props) => (props.$settings?.imageRoundedCorners ? '8px' : '0px')};
  :hover {
    cursor: ${(props) => (props.$settings?.buttonConfig?.display === 'none' ? 'arrow' : 'pointer')};
  }
`;
