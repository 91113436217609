import { useIntl } from 'react-intl';
import { type IPageUser } from '../../../../common/interfaces';
import { AVATAR_PLACEHOLDER } from '../../common/const';
import { Avatar } from '../Tabbed/common/styles';

export const UserAvatar = ({ user }: { user: IPageUser }) => {
  const intl = useIntl();
  const avatarSrc = user?.avatarUrl || AVATAR_PLACEHOLDER;
  const avatarAlt = intl.formatMessage({ id: 'page.UsersAvatar', defaultMessage: "User's avatar" });
  const avatarTitle = user?.name;

  if (user?.linkUrl) {
    return (
      <a href={user.linkUrl} title={avatarTitle}>
        <Avatar src={avatarSrc} alt={avatarAlt} />
      </a>
    );
  }

  return <Avatar src={avatarSrc} alt={avatarAlt} title={avatarTitle} />;
};
