import {
  Checkbox,
  color,
  ExpandableContent,
  Grid,
  GridCol,
  GridRow,
  Radio,
  RadioGroup,
  snap,
  typeStyle,
  units
} from '@m/alchemy-ui';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { Body, Container, SectionTitle, SubTitle, Title } from '../../common/components';
import { ChallengeArchived, ChallengeSorting, SelectorType } from '../../common/enums';
import { type IChallenge } from '../../common/interfaces';
import { AccessOptions, AccessSelector, Categories, CustomizeForm, VotingOptions, VotingSelector } from './components';
import { useChallenge } from './context/Challenge';

export interface SelectionChange {
  handleChange: (option: Record<string, unknown>) => void;
}

const StyledExpandableContent = styled(ExpandableContent)`
  margin-top: ${units(4)};
  & > button {
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    color: inherit;
    text-align: left;
  }
  & > div {
    padding-left: 0;
  }
`;

const TitleText = styled.span`
  display: block;
  ${typeStyle('headerS')};
`;

const BodyText = styled.span`
  ${typeStyle('bodyS', { marginStrategy: 'collapse' })};
`;

const StyledRadioGroup = styled(RadioGroup)`
  div {
    display: flex;
    align-items: flex-start;
    width: ${snap(500)};
    max-width: 100%;
  }
`;

const SortingOptions = styled.div`
  max-width: ${snap(300)};
  width: 100%;
`;
const SortingOptionsHead = styled.div`
  display: flex;
  border-bottom: 2px solid ${color('line')};
  & > * {
    width: 25%;
    padding: ${units(1)};
    text-align: right;
    &:first-child {
      width: 100%;
    }
  }
`;
const SortingOptionsOption = styled.div`
  display: flex;
  align-items: center;
  padding: ${units(0.5)};
  label {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    flex: 1;
    padding: 0 ${units(2)} 0 0;
    & > span {
      position: relative;
      transform: none;
      margin-right: auto;
      &:last-of-type {
        display: block;
        width: 100%;
        white-space: nowrap;
      }
    }
    &:last-of-type {
      flex: 0;
      justify-content: center;
      flex-basis: 25%;
      padding: 0;
      & > span {
        display: inline;
        width: ${units(2)};
        margin-right: 0;
      }
    }
  }
`;

export const Settings = () => {
  const intl = useIntl();
  const { values: challenge, handleChange } = useFormikContext<IChallenge>();
  const { isFunnel, isMilestone } = challenge;

  const { challenge: challengeContext } = useChallenge();

  const focusErrorField = challengeContext.focusError?.field || '';
  const [advancedOptionsOpen, setAdvancedOptionsOpen] = useState(focusErrorField.includes('questions') || false);

  useEffect(() => {
    if (focusErrorField.includes('questions')) {
      setAdvancedOptionsOpen(true);
    }
  }, [focusErrorField]);

  return (
    <>
      <Container>
        {!isFunnel && (
          <>
            <Title>
              <FormattedMessage id="access" defaultMessage="Access" />
            </Title>
            <Grid>
              <GridRow>
                <GridCol span={{ small: 12, medium: 4, large: 4 }}>
                  <AccessSelector type={SelectorType.CHALLENGE} />
                </GridCol>
                <GridCol span={{ small: 12, medium: 8, large: 8 }}>
                  <AccessOptions type={SelectorType.CHALLENGE} />
                </GridCol>
              </GridRow>
            </Grid>
          </>
        )}
        {!isFunnel && !isMilestone && (
          <>
            <SectionTitle>
              <FormattedMessage id="voting" defaultMessage="Voting" />
            </SectionTitle>
            <Grid>
              <GridRow>
                <GridCol span={{ small: 12, medium: 4 }}>
                  <SubTitle>
                    <FormattedMessage
                      id="selectVotingChallenge"
                      defaultMessage="Select a type of voting for the challenge"
                    />
                  </SubTitle>
                  <VotingSelector type={SelectorType.CHALLENGE} />
                </GridCol>
                <GridCol span={{ small: 12, medium: 8 }}>
                  <VotingOptions type={SelectorType.CHALLENGE} />
                </GridCol>
              </GridRow>
            </Grid>
          </>
        )}
        <StyledExpandableContent
          hasDivider={false}
          isOpen={advancedOptionsOpen}
          onClick={() => setAdvancedOptionsOpen(!advancedOptionsOpen)}
          label={
            <>
              <TitleText>
                <FormattedMessage id="advancedOptions" defaultMessage="Advanced Options" />
              </TitleText>
              <BodyText>
                <FormattedMessage
                  id="advancedOptionsSub"
                  defaultMessage="Include Categories, Sorting, and form customisation."
                />
              </BodyText>
            </>
          }
        >
          <SectionTitle>
            <FormattedMessage id="customiseForm" defaultMessage="Customise Form" />
          </SectionTitle>
          <Body>
            <FormattedMessage
              id="customiseFormIntro"
              defaultMessage="Customise the idea submission form. Add questions or change the wording of existing fields."
            />
          </Body>
          <CustomizeForm type={SelectorType.CHALLENGE} />

          <Categories />

          <SectionTitle>
            <FormattedMessage id="ideaSortingTitle" defaultMessage="Idea sorting options" />
          </SectionTitle>

          <SortingOptions>
            <SortingOptionsHead>
              <span>
                <FormattedMessage id="visible" defaultMessage="Visible" />
              </span>
              <span>
                <FormattedMessage id="default" defaultMessage="Default" />
              </span>
            </SortingOptionsHead>

            <SortingOptionsOption>
              <Checkbox
                value={ChallengeSorting.NEWEST}
                onChange={handleChange}
                checked={challenge.config.sorting.options.includes(ChallengeSorting.NEWEST)}
                name="config.sorting.options"
                label={intl.formatMessage({ id: 'latest', defaultMessage: 'Latest' })}
              />
              <Radio
                value={ChallengeSorting.NEWEST}
                label=""
                onChange={handleChange}
                name="config.sorting.default"
                checked={challenge.config.sorting.default === ChallengeSorting.NEWEST}
              />
            </SortingOptionsOption>
            <SortingOptionsOption>
              <Checkbox
                value={ChallengeSorting.TRENDING}
                onChange={handleChange}
                checked={challenge.config.sorting.options.includes(ChallengeSorting.TRENDING)}
                name="config.sorting.options"
                label={intl.formatMessage({ id: 'trending', defaultMessage: 'Trending' })}
              />
              <Radio
                value={ChallengeSorting.TRENDING}
                label=""
                onChange={handleChange}
                name="config.sorting.default"
                checked={challenge.config.sorting.default === ChallengeSorting.TRENDING}
              />
            </SortingOptionsOption>
            <SortingOptionsOption>
              <Checkbox
                value={ChallengeSorting.OLDEST}
                onChange={handleChange}
                checked={challenge.config.sorting.options.includes(ChallengeSorting.OLDEST)}
                name="config.sorting.options"
                label={intl.formatMessage({ id: 'oldest', defaultMessage: 'Oldest' })}
              />
              <Radio
                value={ChallengeSorting.OLDEST}
                label=""
                onChange={handleChange}
                name="config.sorting.default"
                checked={challenge.config.sorting.default === ChallengeSorting.OLDEST}
              />
            </SortingOptionsOption>
            <SortingOptionsOption>
              <Checkbox
                value={ChallengeSorting.POPULAR}
                onChange={handleChange}
                checked={challenge.config.sorting.options.includes(ChallengeSorting.POPULAR)}
                name="config.sorting.options"
                label={intl.formatMessage({ id: 'highestRated', defaultMessage: 'Highest Rated' })}
              />
              <Radio
                value={ChallengeSorting.POPULAR}
                label=""
                onChange={handleChange}
                name="config.sorting.default"
                checked={challenge.config.sorting.default === ChallengeSorting.POPULAR}
              />
            </SortingOptionsOption>
            <SortingOptionsOption>
              <Checkbox
                value={ChallengeSorting.COMMENTS}
                onChange={handleChange}
                checked={challenge.config.sorting.options.includes(ChallengeSorting.COMMENTS)}
                name="config.sorting.options"
                label={intl.formatMessage({ id: 'mostDiscussed', defaultMessage: 'Most Discussed' })}
              />
              <Radio
                value={ChallengeSorting.COMMENTS}
                label=""
                onChange={handleChange}
                name="config.sorting.default"
                checked={challenge.config.sorting.default === ChallengeSorting.COMMENTS}
              />
            </SortingOptionsOption>
            <SortingOptionsOption>
              <Checkbox
                value={ChallengeSorting.RANDOM}
                onChange={handleChange}
                checked={challenge.config.sorting.options.includes(ChallengeSorting.RANDOM)}
                name="config.sorting.options"
                label={intl.formatMessage({ id: 'random', defaultMessage: 'Random' })}
              />
              <Radio
                value={ChallengeSorting.RANDOM}
                label=""
                onChange={handleChange}
                name="config.sorting.default"
                checked={challenge.config.sorting.default === ChallengeSorting.RANDOM}
              />
            </SortingOptionsOption>
          </SortingOptions>
        </StyledExpandableContent>

        <SectionTitle>
          <FormattedMessage id="publishChallenge" defaultMessage="Publish this challenge" />
        </SectionTitle>

        <StyledRadioGroup onChange={(option) => handleChange({ target: option })}>
          {({ handleChange }: SelectionChange) => [
            <Radio
              key={ChallengeArchived.PUBLISHED}
              label={
                challenge?.isNew
                  ? intl.formatMessage({ id: 'yesAndPublish', defaultMessage: 'Yes & publish now' })
                  : intl.formatMessage({ id: 'published', defaultMessage: 'Published' })
              }
              name="archived"
              checked={challenge?.archived === ChallengeArchived.PUBLISHED || !challenge?.archived}
              onChange={() => handleChange({ name: 'archived', value: ChallengeArchived.PUBLISHED })}
            />,
            <Radio
              key={ChallengeArchived.UNPUBLISHED}
              label={
                challenge?.isNew
                  ? intl.formatMessage({ id: 'noSaveDraft', defaultMessage: 'No, save as draft' })
                  : intl.formatMessage({ id: 'unpublished', defaultMessage: 'Unpublished' })
              }
              name="archived"
              checked={challenge?.archived === ChallengeArchived.UNPUBLISHED}
              onChange={() => handleChange({ name: 'archived', value: ChallengeArchived.UNPUBLISHED })}
            />
          ]}
        </StyledRadioGroup>
      </Container>
    </>
  );
};
