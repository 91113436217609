import { decode } from 'html-entities';
import { type IPageItem } from '../../../../common/interfaces';

/* eslint-disable react/no-danger */
export const Video = (props: { item: IPageItem }) => (
  <div
    data-testid="videoComponent"
    dangerouslySetInnerHTML={{
      __html: decode(props.item?.settings?.videoEmbedCode)
    }}
  />
);
