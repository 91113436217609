import styled from 'styled-components';

export const TextContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-right: 15px;

  a {
    margin-right: 15px;
    text-decoration: none;
    white-space: normal;
    word-break: break-word;
    flex-grow: 1;
  }
`;

export const SpanContainer = styled.span`
  display: flex;
  align-items: center;
  margin-right: 15px;
`;

export const Points = styled.span`
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
`;
