import { useMutation, useQuery } from '@apollo/client';
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  EditIcon,
  Field,
  IconButton,
  Input,
  LoadingIndicator,
  Table,
  TableCell,
  TableRow,
  TextArea
} from '@m/alchemy-ui';
import { hasValue } from '@m/magic-typescript';
import { Form, Formik, type FormikHelpers } from 'formik';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonRow, Container, Title } from '../../../../common/components';
import { ApiClientStatusType, type ApiRequest, ApiRequestStatusType } from '../../../../common/core-api';
import {
  SAVE_API_CLIENT,
  SAVE_API_REQUEST,
  type SaveApiClientMutation,
  type SaveApiRequestMutation
} from '../../../../graphql/mutations';
import { type ApiRequestsQuery, GET_API_REQUESTS } from '../../../../graphql/queries';
import * as Styled from './Api.styled';

export interface ApiEdit {
  id: string;
  requestId: string;
  regeneratePassword?: boolean;
  password?: string;
}

export const Api = () => {
  const intl = useIntl();
  const { data, loading: apiRequestsLoading } = useQuery<ApiRequestsQuery>(GET_API_REQUESTS);
  const [saveApiRequest, { loading: saveApiRequestLoading }] = useMutation<SaveApiRequestMutation>(SAVE_API_REQUEST, {
    update(cache, { data }) {
      if (!data?.saveApiRequest) {
        return;
      }

      cache.modify({
        fields: {
          apiRequests(existingApiRequests) {
            return [...(existingApiRequests as ApiRequest[]), data.saveApiRequest].filter(hasValue);
          }
        }
      });
    }
  });
  const [saveApiClient, { loading: saveApiClientLoading }] = useMutation<SaveApiClientMutation>(SAVE_API_CLIENT);

  const [applyActive, setApplyActive] = useState(false);
  const [activeApiClient, setActiveApiClient] = useState<ApiEdit | null>(null);

  const handleApiClientChange = async ({ id, requestId, regeneratePassword = false }: ApiEdit) => {
    const clientUpdate = await saveApiClient({
      variables: { input: { id, requestId, regeneratePassword } }
    });
    if (activeApiClient) {
      setActiveApiClient({ ...activeApiClient, password: clientUpdate.data?.saveApiClient?.password || undefined });
    }
  };

  const handleApiRequestSubmit = (input: { description: string }, formik: FormikHelpers<{ description: string }>) => {
    if (input.description === '') {
      formik.setErrors({
        description: intl.formatMessage({ id: 'descriptionEmptyError', defaultMessage: 'Description cannot be empty' })
      });
    } else {
      saveApiRequest({ variables: { input } });
      setApplyActive(false);
      formik.resetForm();
    }
  };

  const apiRequestStatus = {
    [ApiRequestStatusType.APPLIED]: intl.formatMessage({ id: 'applied', defaultMessage: 'Applied' }),
    [ApiRequestStatusType.APPROVED]: intl.formatMessage({ id: 'approved', defaultMessage: 'Approved' }),
    [ApiRequestStatusType.REFUSED]: intl.formatMessage({ id: 'refused', defaultMessage: 'Refused' })
  };

  const apiClientStatus = {
    [ApiClientStatusType.ACTIVE]: intl.formatMessage({ id: 'active', defaultMessage: 'Active' }),
    [ApiClientStatusType.LOCKED]: intl.formatMessage({ id: 'locked', defaultMessage: 'Locked' }),
    [ApiClientStatusType.REMOVED]: intl.formatMessage({ id: 'removed', defaultMessage: 'Removed' })
  };

  if (apiRequestsLoading) {
    return <LoadingIndicator />;
  }

  const clients = (data?.apiRequests || []).filter((request) => request.client !== null);

  return (
    <Container>
      <Styled.ApiBox depth={1}>
        <Title>
          <FormattedMessage id="yourApiClients" defaultMessage="Your API Clients" />
        </Title>
        {clients.length === 0 && (
          <Alert>
            <FormattedMessage id="noClientsCreatedyet" defaultMessage="No clients created yet" />
          </Alert>
        )}

        {clients.length > 0 && (
          <Table>
            <thead>
              <TableRow>
                <TableCell isHeader />
                <TableCell isHeader>
                  <FormattedMessage id="key" defaultMessage="Key" />
                </TableCell>
                <TableCell isHeader colSpan={2}>
                  <FormattedMessage id="description" defaultMessage="Description" />
                </TableCell>
              </TableRow>
            </thead>
            <tbody>
              {clients.map((request) => (
                <TableRow key={request.applied}>
                  <TableCell>
                    {request.client?.status === ApiClientStatusType.ACTIVE && (
                      <IconButton
                        icon={<EditIcon aria-label={intl.formatMessage({ id: 'edit', defaultMessage: 'Edit' })} />}
                        tooltipContents={intl.formatMessage({ id: 'edit', defaultMessage: 'Edit' })}
                        onClick={() =>
                          setActiveApiClient({
                            id: String(request.client?.id),
                            requestId: request.id
                          })
                        }
                      />
                    )}
                    {request.client?.status &&
                      request.client?.status !== ApiClientStatusType.ACTIVE &&
                      apiClientStatus[ApiClientStatusType[request.client?.status]]}
                  </TableCell>
                  <TableCell>
                    <b>{request.client?.id}</b>
                  </TableCell>
                  <TableCell>{request.description}</TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
        )}
      </Styled.ApiBox>

      <Styled.ApiBox depth={1}>
        <Title>
          <FormattedMessage id="accessRequests" defaultMessage="Access Requests" />
        </Title>

        {data?.apiRequests?.length === 0 && (
          <Alert>
            <FormattedMessage id="noRequestsCreatedYet" defaultMessage="No requests created yet" />
          </Alert>
        )}

        {(data?.apiRequests || []).length > 0 && (
          <Table>
            <thead>
              <TableRow>
                <TableCell isHeader>
                  <FormattedMessage id="date" defaultMessage="Date" />
                </TableCell>
                <TableCell isHeader>
                  <FormattedMessage id="description" defaultMessage="Description" />
                </TableCell>
                <TableCell isHeader>
                  <FormattedMessage id="status" defaultMessage="Status" />
                </TableCell>
              </TableRow>
            </thead>
            <tbody>
              {data?.apiRequests?.map((request) => (
                <TableRow key={request.applied}>
                  <TableCell>{new Date(request.applied).toLocaleString()}</TableCell>
                  <TableCell>{request.description}</TableCell>
                  <TableCell>{apiRequestStatus[request.status]}</TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
        )}
      </Styled.ApiBox>

      <Styled.Section>
        {!applyActive && (
          <Button type="button" priority="secondary" onClick={() => setApplyActive(true)}>
            <FormattedMessage id="applyForApi" defaultMessage="Apply for a new API client" />
          </Button>
        )}

        {applyActive && (
          <Formik initialValues={{ description: '' }} onSubmit={handleApiRequestSubmit}>
            {({ handleChange, values, errors }) => (
              <Form>
                <Field
                  isFullWidth
                  label={intl.formatMessage({
                    id: 'apiReasonLabel',
                    defaultMessage: 'Please enter a brief description of how you want to use the API.'
                  })}
                  description={intl.formatMessage({
                    id: 'apiReasonHelp',
                    defaultMessage: 'Your request will be reviewed and if accepted, approved by a moderator.'
                  })}
                  input={<TextArea name="description" value={values.description} onChange={handleChange} />}
                  status={errors.description ? { level: 'error', message: errors.description } : undefined}
                />
                <ButtonRow>
                  <Button type="submit" isLoading={saveApiRequestLoading}>
                    <FormattedMessage id="apply" defaultMessage="Apply" />
                  </Button>
                </ButtonRow>
              </Form>
            )}
          </Formik>
        )}
      </Styled.Section>

      {activeApiClient && (
        <Dialog
          isOpen={activeApiClient !== null}
          onClose={() => setActiveApiClient(null)}
          headerContent={intl.formatMessage({ id: 'generatePassword', defaultMessage: 'Generate password ' })}
          hasCloseIcon
          size="medium"
        >
          <Formik initialValues={activeApiClient} onSubmit={handleApiClientChange}>
            {({ handleChange, values }) => (
              <Form>
                <Styled.Field
                  isFullWidth
                  description={intl.formatMessage({
                    id: 'apiGeneratePasswordDescription',
                    defaultMessage:
                      'Ticking "Generate password" and clicking "Save" will create a new password for this client_id. The password will be displayed until you leave or refresh this page, and cannot be retrieved again, so be sure to store it safely.'
                  })}
                  input={
                    <Checkbox
                      label={intl.formatMessage({ id: 'regeneratePassword', defaultMessage: 'Generate password' })}
                      name="regeneratePassword"
                      value="true"
                      checked={values.regeneratePassword || false}
                      onChange={handleChange}
                    />
                  }
                />
                {activeApiClient.password && (
                  <Styled.Field
                    isFullWidth
                    label={intl.formatMessage({ id: 'generatedPassword', defaultMessage: 'Generated password:' })}
                    input={<Input name="generatedPassword" readOnly value={activeApiClient.password} />}
                  />
                )}
                <ButtonRow>
                  <Button type="submit" disabled={!values.regeneratePassword} isLoading={saveApiClientLoading}>
                    <FormattedMessage id="save" defaultMessage="Save" />
                  </Button>
                </ButtonRow>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}
    </Container>
  );
};
