import { type ReactNode, type SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { flushSync } from 'react-dom';
import { useActionKey } from '../hooks';
import { DialogContext } from '../context';
import { getPopupData } from '../helpers';

export const useUserCreatedChallengeForm = () => {
  const intl = useIntl();
  const actionKey = useActionKey();
  return useAjaxDialog({
    url: `/category/user_create_challenge_form/?ak=${actionKey}`,
    header: intl.formatMessage({ id: 'createAChallenge', defaultMessage: 'Create a Challenge' }),
    hasStickyFooter: true,
    dialogProps: {
      size: 'large'
    },
    onDialogLoaded() {
      window.applicationEventAction('intializeGuiElements');
    }
  });
};

export const useProfilePreviewDialog = () => {
  const intl = useIntl();
  const { loadDialog, ...rest } = useAjaxDialog({
    header: intl.formatMessage({ id: 'profile', defaultMessage: 'Profile' }),
    hasStickyFooter: true,
    dialogProps: {
      size: 'large'
    }
  });

  return {
    ...rest,
    loadProfilePreviewDialog: async (id: number | string) => loadDialog(`user/view/${id}?popUpTitle=Profile`)
  };
};

export const useSignInDialog = () =>
  useAjaxDialog({
    url: `user/signinform`,
    dialogProps: {
      size: 'large'
    }
  });

export interface AjaxDialogLoadedProps {
  closeDialog: () => void;
}

export const usePostIdeaDialog = () => {
  const intl = useIntl();
  return useAjaxDialog({
    url: `post/add?isExternal=false`,
    dialogProps: {
      size: 'large',
      padding: 0,
      shouldCloseOnEsc: false,
      shouldCloseOnOutsideClick: false
    },
    header: intl.formatMessage({ id: 'postAnIdea', defaultMessage: 'PostAnIdea' }),
    showLoading: true,
    onDialogLoaded({ closeDialog }: AjaxDialogLoadedProps) {
      const cancelButtons = document.querySelectorAll('.cancel-idea-trigger');
      cancelButtons.forEach((button) => {
        button.addEventListener('click', (e) => {
          e.preventDefault();
          closeDialog();
        });
      });
    }
  });
};

export const useRegisterDialog = () =>
  useAjaxDialog({
    url: `user/registerform`,
    dialogProps: {
      size: 'medium',
      ' aria-describedby': 'register_info'
    },
    onDialogLoaded({ closeDialog }: AjaxDialogLoadedProps) {
      const cancelButton = document.querySelector('#fullContainer .mdl-button.-tertiary');
      cancelButton?.addEventListener('click', (e) => {
        e.preventDefault();
        closeDialog();
      });
    }
  });

export const useInvitesDialog = () =>
  useAjaxDialog({
    url: `/user/invite`,
    dialogProps: {
      width: 'auto'
    }
  });

export interface AjaxDialogProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dialogProps?: { [key: string]: any };
  footer?: ReactNode | string;
  hasStickyFooter?: boolean;
  header?: ReactNode | string;
  onDialogLoaded?: ({ closeDialog }: AjaxDialogLoadedProps) => void;
  showDialog?: boolean;
  showLoading?: boolean;
  target?: string;
  url?: string;
}

export const useAjaxDialog = ({
  dialogProps = {},
  footer = null,
  hasStickyFooter = false,
  header = null,
  onDialogLoaded = () => {},
  showDialog = true,
  showLoading = false,
  url = ''
}: AjaxDialogProps) => {
  const {
    openDialog,
    closeDialog,
    setData,
    setHeader,
    setFooter,
    isDialogOpen,
    isDialogLoading,
    setHasStickyFooter,
    setIsDialogLoading,
    setDialogProps,
    setScripts
  } = useContext(DialogContext);
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('notifyPopupClosed', closeDialog);

    return () => {
      window.removeEventListener('notifyPopupClosed', closeDialog);
    };
  }, [closeDialog]);

  const loadDialog = async (overrideUrl?: SyntheticEvent | string) => {
    hasStickyFooter && setHasStickyFooter(true);
    header && setHeader(header);
    footer && setFooter(footer);
    showDialog && openDialog();
    showLoading && setIsDialogLoading(true);
    Object.keys(dialogProps).length > 0 && setDialogProps(dialogProps);

    setIsRequestLoading(true);

    const path = typeof overrideUrl === 'object' || !overrideUrl ? url : overrideUrl;
    const result = (await getPopupData(path)) as Document;

    const content = result.querySelector('body');
    const bodyScripts = result.querySelectorAll<HTMLScriptElement>('body script');

    content &&
      flushSync(() => {
        setData(content.outerHTML);
      });

    if (bodyScripts.length > 0) {
      !isDialogOpen && openDialog();

      setScripts(bodyScripts);
    }

    setIsRequestLoading(false);
    setIsDialogLoading(false);
    onDialogLoaded({ closeDialog });
  };

  // return access to all underlying Dialog functionality
  return {
    closeDialog,
    isDialogLoading,
    isDialogOpen,
    isRequestLoading,
    loadDialog,
    openDialog,
    setData,
    setHeader,
    setIsDialogLoading,
    setDialogProps
  } as const;
};
