import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  flex-flow: column;
  max-width: 100%;

  & + & {
    margin-left: 20px;
  }
`;

export const StyledIcon = styled.span`
  display: inline-block;
  width: 16;
  height: 16;
  vertical-align: middle;
  transition: transform 0.3s ease-in-out;
`;
