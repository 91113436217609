import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { Field, type Option, Select, snap } from '@m/alchemy-ui';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { Container } from '../../common/components';
import { ChallengeType, WarningType } from '../../common/enums';
import { CommunityContext } from '../../context';
import { defaultChallengeVisibility, defaultVoting, type IChallenge } from '../../common/interfaces';
import { ChallengeTypes, FunnelEditor, MilestoneEditor, Warning } from './components';

const StyledField = styled(Field)`
  width: 100%;
  max-width: ${snap(500)};
`;
const StyledLabel = styled.div`
  &:before {
    content: '— ';
  }
  &:after {
    content: ' —';
  }
`;

export const Type = () => {
  const { values: challenge, setFieldValue } = useFormikContext<IChallenge>();
  const community = useContext(CommunityContext);
  const intl = useIntl();

  const { isFunnel, isMilestone, ideas, active } = challenge;

  const getChallengeType = (value?: string) => {
    if (value === 'funnel') {
      return ChallengeType.FUNNEL;
    }

    if (value === 'milestone') {
      return ChallengeType.MILESTONE;
    }

    return ChallengeType.STANDARD;
  };

  const initialChallengeType =
    isFunnel || isMilestone ? (isFunnel ? ChallengeType.FUNNEL : ChallengeType.MILESTONE) : ChallengeType.STANDARD;

  const [challengeType, setChallengeType] = useState(initialChallengeType);
  const [selectedChallengeType, setSelectedChallengeType] = useState('');
  const [warningOpen, setWarningOpen] = useState(false);
  const [warningType, setWarningType] = useState<WarningType>();

  const typeChangeDisabled = (isFunnel || isMilestone) && (Number(ideas?.length) > 0 || active === 1);

  const selectTypeData = [
    {
      label: (
        <StyledLabel>
          {intl.formatMessage({
            id: 'selectChallengeType',
            defaultMessage: 'Select Challenge Type'
          })}
        </StyledLabel>
      ),
      value: '',
      disabled: true
    },
    {
      label: intl.formatMessage({
        id: 'standard',
        defaultMessage: 'Standard'
      }),
      value: ChallengeType.STANDARD
    },
    {
      label: intl.formatMessage({
        id: 'funnel',
        defaultMessage: 'Funnel'
      }),
      value: ChallengeType.FUNNEL
    },
    {
      label: intl.formatMessage({
        id: 'milestone',
        defaultMessage: 'Milestone'
      }),
      value: ChallengeType.MILESTONE
    }
  ];

  const onChallengeTypeChange = ({ value }: Option) => {
    if (challengeType === ChallengeType.FUNNEL) {
      if (value === ChallengeType.STANDARD) {
        setWarningType(WarningType.FUNNEL_TO_STANDARD);
      }

      if (value === ChallengeType.MILESTONE) {
        setWarningType(WarningType.FUNNEL_TO_MILESTONE);
      }

      setSelectedChallengeType(value);
      setWarningOpen(true);
    }

    if (challengeType === ChallengeType.MILESTONE) {
      if (value === ChallengeType.STANDARD) {
        setWarningType(WarningType.MILESTONE_TO_STANDARD);
      }

      if (value === ChallengeType.FUNNEL) {
        setWarningType(WarningType.MILESTONE_TO_FUNNEL);
      }

      setSelectedChallengeType(value);
      setWarningOpen(true);
    }

    if (challengeType === ChallengeType.STANDARD) {
      if (value === ChallengeType.FUNNEL) {
        setFieldValue('isFunnel', true);
        setFieldValue('access', null);
      }

      if (value === ChallengeType.MILESTONE) {
        setFieldValue('isFunnel', false);
        setFieldValue('isMilestone', true);
      }

      setChallengeType(getChallengeType(value));
    }
  };

  const handleWarningClose = () => {
    setWarningOpen(false);
  };

  const handleWarningConfirmed = () => {
    setWarningOpen(false);
    setChallengeType(getChallengeType(selectedChallengeType));

    const defaultChallengeAccess = {
      users: community.defaultAccess?.users,
      groups: community.defaultAccess?.groups,
      options: defaultChallengeVisibility
    };

    if (challengeType === ChallengeType.FUNNEL) {
      setFieldValue('isFunnel', false);
      setFieldValue('phases', null);
      setFieldValue('access', defaultChallengeAccess);
      setFieldValue('voting', defaultVoting);

      if (selectedChallengeType === ChallengeType.MILESTONE) {
        setFieldValue('isMilestone', true);
      }
    }

    if (challengeType === ChallengeType.MILESTONE) {
      setFieldValue('isMilestone', false);
      setFieldValue('milestone', null);

      if (selectedChallengeType === ChallengeType.FUNNEL) {
        setFieldValue('isFunnel', true);
        setFieldValue('access', null);
      }
    }
  };

  return (
    <Container>
      <StyledField
        layoutVariant="inline"
        label={intl.formatMessage({ id: 'challengeType', defaultMessage: 'Challenge Type' })}
        labelVariant="emphasized"
        required
        isFullWidth
        disabled={typeChangeDisabled || undefined}
        input={
          <Select
            defaultValue={challengeType}
            options={selectTypeData}
            isFullWidth
            value={challengeType}
            onChange={onChallengeTypeChange}
          />
        }
      />
      {challengeType === 'standard' && <ChallengeTypes />}
      {challengeType === 'funnel' && <FunnelEditor />}
      {challengeType === 'milestone' && <MilestoneEditor />}
      <Warning
        isOpen={warningOpen}
        onClose={handleWarningClose}
        onConfirm={handleWarningConfirmed}
        warningType={warningType}
      />
    </Container>
  );
};
