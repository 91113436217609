import styled from 'styled-components';

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-content: flex-start;
  padding: 0;
  overflow: visible;
  margin: 8px 8px 0;

  @media (max-width: 760px) {
    max-width: 100%;
    flex-direction: column;
  }
`;

export const ListItem = styled.li<{ $panelSize: number; $hasWideSibling: boolean }>`
  display: flex;
  flex-grow: 1;
  align-content: flex-start;
  position: relative;
  padding: 0;
  overflow: hidden;
  margin: 8px 0 0 0;
  min-height: 68px;
  max-height: 100px;
  ${(props) => (props.$panelSize === 1 && !props.$hasWideSibling ? `max-width: 33%; min-width: 33%;` : '')}
  ${(props) => (props.$panelSize === 2 && !props.$hasWideSibling ? `max-width: 50%;` : '')}
  ${(props) => (props.$panelSize === 3 || props.$hasWideSibling ? `max-width: 100%;` : '')};

  @media (max-width: 760px) {
    max-width: 100%;
  }
`;

export const ItemText = styled.div`
  flex: 1;
  max-width: 100%;
  margin-left: 10px;
  margin-right: 10px;
`;

export const Avatar = styled.img`
  display: flex;
  border-radius: 50%;
  min-width: 36px;
  min-height: 36px;
  max-width: 36px;
  max-height: 36px;
  margin-right: 10px;
`;

export const Alert = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 16px 0;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: #eaf3ff;
  border: 1px solid #b9dfff;
  width: 100%;
`;

export const Meta = styled.div`
  display: flex;
  display: block;
  gap: 5px;
  margin-top: 2px;
  font-size: 11px;
  line-height: 16px;
  color: #4e4e4e;
`;
