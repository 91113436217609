import { FormattedMessage } from 'react-intl';
import { CommentBubbleIcon } from '@m/alchemy-ui';
import { type IBlog } from '../../../../common/interfaces';
import { NO_IMAGE_PLACEHOLDER } from '../../common/const';
import {
  CardList,
  CardListBody,
  CardListBodyLink,
  CardListIcon,
  CardListImage,
  CardListItem,
  CardListMeta,
  Link,
  LinkContainer
} from './BlogList.styled';
import { Card, StyledIcon } from './common/styles';

interface IBlogListProps {
  data: IBlog[];
}

export const BlogList = ({ data }: IBlogListProps) => (
  <Card>
    <CardList>
      {data.map((item) => (
        <CardListItem key={item.linkUrl} data-testid="blog-list-item">
          <CardListIcon href={item.linkUrl} title={item.title}>
            <CardListImage src={item.imageThumbUrl || NO_IMAGE_PLACEHOLDER} alt="" />
          </CardListIcon>
          <CardListBody>
            <CardListBodyLink href={item.linkUrl} title={item.title} dangerouslySetInnerHTML={{ __html: item.title }} />
            {item.posted}
          </CardListBody>
          <CardListMeta>
            <StyledIcon>
              <CommentBubbleIcon aria-hidden="true" />
            </StyledIcon>
            {` ${item.commentCount || 0}`}
          </CardListMeta>
        </CardListItem>
      ))}
    </CardList>
    <LinkContainer>
      <Link href="/blog" className="mdl-button">
        <FormattedMessage id="page.viewAllBlogs" defaultMessage="View all blogs" />
      </Link>
    </LinkContainer>
  </Card>
);
