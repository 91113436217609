import { MediaButton, PlayPauseContainer } from './PlayPauseButton.styled';

interface PlayPauseButtonProps {
  isPlaying: boolean;
  togglePlayPause: () => void;
}

export const PlayPauseButton = ({ isPlaying, togglePlayPause }: PlayPauseButtonProps) => (
  <PlayPauseContainer data-testid="play-pause-container" onClick={togglePlayPause}>
    <MediaButton data-testid="media-button">
      <svg>
        <use href={isPlaying ? '#pause' : '#play'} />
      </svg>
    </MediaButton>
  </PlayPauseContainer>
);
