import styled from 'styled-components';

export const CardList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 12px;
`;

export const CardListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const CardListIcon = styled.a`
  align-self: flex-start;
  display: block;
  width: 62px;
  min-width: 62px;
  margin-right: 10px;
`;

export const CardListImage = styled.img`
  max-width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

export const CardListBody = styled.div`
  margin-right: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #949494;
`;

export const CardListBodyLink = styled.a`
  display: block;
  font-weight: 500;
`;

export const CardListMeta = styled.div`
  margin-left: auto;
  color: #949494;
  white-space: nowrap;
`;

export const LinkContainer = styled.div`
  padding: 0 12px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
`;

export const Link = styled.a`
  width: 100%;
`;
