import styled from 'styled-components';

export const PlayPauseContainer = styled.div`
  position: absolute;
  right: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const MediaButton = styled.span`
  svg {
    width: 30px;
    height: 30px;
  }
`;
