import styled from 'styled-components';

export const BubbleText = styled.div`
  display: block;
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
  line-height: 5px;
`;

export const Bubble = styled.div<{ bgColor: string }>`
  display: block;
  position: relative;
  min-width: 60px;
  height: 40px;
  background-color: ${({ bgColor }) => `#${bgColor}`};
  color: white;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  border-radius: 5px;
`;

export const StarsList = styled.ol`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 60px;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;
