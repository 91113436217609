import styled from 'styled-components';
import { type CustomPageRowProps } from './CommunityPage';

export const CommunityMainPage = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1200px;
  overflow: hidden;
  padding-top: 10px;
`;

export const CustomPageRow = styled.div<Pick<CustomPageRowProps, 'itemsCount' | 'hasWideItem'>>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: 600px) {
    display: block;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    ${(props) =>
      (props.itemsCount === 3 || (props.itemsCount === 2 && !props.hasWideItem)) &&
      `
        display: flex;
        flex-flow: column;
  `}
  }
`;
