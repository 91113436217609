import styled from 'styled-components';

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
  border-radius: 8px;
`;

export const CarouselSlides = styled.div<{ currentSlide: number }>`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: ${({ currentSlide }) => `translateX(-${currentSlide * 100}%)`};
  height: auto;
`;

export const NavButton = styled.button<{ left?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  color: grey;
  border: none;
  padding: 2rem;
  cursor: pointer;
  opacity: 0;
  border-radius: 50%;
  font-size: 1rem;
  ${({ left }) =>
    left
      ? 'padding-right: 0.8rem; left: 0px; transform: translateX(-55%);'
      : 'padding-left: 0.8rem; right: 0px; transform: translateX(55%);'}

  #carousel-container:hover & {
    opacity: 1;
  }

  @media (max-width: 600px) {
    top: 100px;
  }
`;

export const DotsContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export const Dot = styled.span<{ active: boolean }>`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background: ${({ active }) => (active ? '#1114B4' : 'lightblue')};
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.5;
`;
