/* eslint-disable jsx-a11y/anchor-is-valid */
import { type MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { type ILeaderboardItem } from '../../../../../common/interfaces';
import { useMainModal } from '../../../../../hooks';
import { LeaderboardTabKeys } from '../../../../../common/enums/LeaderboardTabKeys';
import { ListItem } from '../common/styles';
import { UserAvatar } from '../../UserAvatar/UserAvatar';
import { Points, SpanContainer, TextContent } from './LeaderboardItem.styled';

interface ILeaderboardItemProps {
  id: number;
  data: ILeaderboardItem;
  tab: string;
  panelSize: number;
  hasWideSibling: boolean;
}

export const LeaderboardItem = ({ id, data, tab, panelSize, hasWideSibling }: ILeaderboardItemProps) => {
  const intl = useIntl();
  const [openModal] = useMainModal();

  const openUserProfile = (e: MouseEvent<HTMLAnchorElement>, url: string) => {
    openModal(e, { title: intl.formatMessage({ id: 'profile', defaultMessage: 'Profile' }), url });
  };

  const createActionSourceLink = (item: ILeaderboardItem) => (
    <a href="#" role="button" onClick={(e) => openUserProfile(e, `/user/view/${item.user.id}`)}>
      {item.user.name}
    </a>
  );

  return (
    <ListItem $panelSize={panelSize} $hasWideSibling={hasWideSibling}>
      {tab === LeaderboardTabKeys.LEADERBOARD ? <SpanContainer>{id}</SpanContainer> : null}
      <SpanContainer>
        <UserAvatar user={data.user} />
      </SpanContainer>
      <TextContent>
        {createActionSourceLink(data)}
        <Points>{data.event.points}</Points>
      </TextContent>
    </ListItem>
  );
};
