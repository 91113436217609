/* eslint-disable jsx-a11y/anchor-is-valid */
import { FormattedMessage, useIntl } from 'react-intl';
import { type MouseEvent } from 'react';
import { type IActivity } from '../../../../../common/interfaces';
import { EventAction } from '../../../../../common/enums';
import { useMainModal } from '../../../../../hooks';
import { ItemText, ListItem, Meta } from '../common/styles';
import { UserAvatar } from '../../UserAvatar/UserAvatar';

interface IActivityItemProps {
  data: IActivity;
  panelSize: number;
  hasWideSibling: boolean;
}

export const ActivityItem = ({ data, panelSize, hasWideSibling }: IActivityItemProps) => {
  const intl = useIntl();
  const [openModal] = useMainModal();

  const openUserProfile = (e: MouseEvent<HTMLAnchorElement>, url: string) => {
    openModal(e, { title: intl.formatMessage({ id: 'profile', defaultMessage: 'Profile' }), url });
  };

  const createActionSourceLink = (action: IActivity) =>
    action.user.id && Number(action.user.id) > 0 ? (
      <a href="#" onClick={(e) => openUserProfile(e, `/user/view/${action.user.id}`)}>
        {action.event.source}
      </a>
    ) : (
      <>{action.event.source}</>
    );

  const createTargetLink = (name: string, url: string) => <a href={url}>{name}</a>;

  const activityMessage = data.event.source && (
    <>
      {data.event.action === EventAction.VOTE && (
        <FormattedMessage
          id="page.hasVotedOn"
          defaultMessage="{source} has voted on {target}"
          values={{
            source: createActionSourceLink(data),
            target: createTargetLink(data.event.target, data.event.targetUrl)
          }}
        />
      )}
      {data.event.action === EventAction.POST && (
        <FormattedMessage
          id="page.postedTheIdeaIn"
          defaultMessage="{source} posted the idea {target} in {category}"
          values={{
            source: createActionSourceLink(data),
            target: createTargetLink(data.event.target, data.event.targetUrl),
            category: createTargetLink(data.event.category, data.event.categoryUrl)
          }}
        />
      )}
      {data.event.action === EventAction.COMMENT && (
        <FormattedMessage
          id="page.commentedOn"
          defaultMessage="{source} commented on {target}"
          values={{
            source: createActionSourceLink(data),
            target: createTargetLink(data.event.target, data.event.targetUrl)
          }}
        />
      )}
      {data.event.action === EventAction.BLOG_POST && (
        <FormattedMessage
          id="page.postedBlogEntitled"
          defaultMessage="{source} posted a blog entitled {target}"
          values={{
            source: createActionSourceLink(data),
            target: createTargetLink(data.event.target, data.event.targetUrl)
          }}
        />
      )}
      {data.event.action === EventAction.VOTE_COMMENT && (
        <FormattedMessage
          id="page.hasVotedOnCommentOn"
          defaultMessage="{source} has voted on a comment on {target}"
          values={{
            source: createActionSourceLink(data),
            target: createTargetLink(data.event.target, data.event.targetUrl)
          }}
        />
      )}
      {data.event.action === EventAction.USER_FOLLOW && (
        <FormattedMessage
          id="page.isFollowing"
          defaultMessage="{source} is following {target}"
          values={{
            source: createActionSourceLink(data),
            target: createTargetLink(data.event.target, data.event.targetUrl)
          }}
        />
      )}
      {data.event.action === EventAction.POST_FOLLOW && (
        <FormattedMessage
          id="page.hasSubscribedToTheIdea"
          defaultMessage="{source} has subscribed to the idea {target}"
          values={{
            source: createActionSourceLink(data),
            target: createTargetLink(data.event.target, data.event.targetUrl)
          }}
        />
      )}
      {data.event.action === EventAction.BLOG_POST_FOLLOW && (
        <FormattedMessage
          id="page.hasSubscribedToTheBlogPost"
          defaultMessage="{source} has subscribed to the blog post {target}"
          values={{
            source: createActionSourceLink(data),
            target: createTargetLink(data.event.target, data.event.targetUrl)
          }}
        />
      )}
      {data.event.action === EventAction.MILESTONE_REACHED && (
        <FormattedMessage
          id="page.hasReachedMilestone"
          defaultMessage="{source} has reached a milestone"
          values={{ source: createActionSourceLink(data) }}
        />
      )}
      {data.event.action === EventAction.MILESTONE_IDEA_REJECTED && (
        <FormattedMessage
          id="page.hasBeenRejectedByModerator"
          defaultMessage="{source} has been rejected by a moderator"
          values={{ source: createActionSourceLink(data) }}
        />
      )}
      {data.event.action === EventAction.MILESTONE_INCUBATOR_COMPLEATED && (
        <FormattedMessage
          id="page.hasCompletedMilestoneChallenge"
          defaultMessage="{source} has completed a Milestone Challenge"
          values={{ source: createActionSourceLink(data) }}
        />
      )}
      {(data.event.action === EventAction.USER_BADGE || data.event.action === EventAction.USER_BADGES) && (
        <FormattedMessage
          id="page.hasBeenAwardedSingleBadge"
          defaultMessage="{source} has been awarded a {badge} badge"
          values={{
            source: createActionSourceLink(data),
            badge: data.event.target
          }}
        />
      )}
    </>
  );
  return (
    <ListItem $panelSize={panelSize} $hasWideSibling={hasWideSibling}>
      <span>
        <UserAvatar user={data.user} />
      </span>
      <ItemText>
        {activityMessage && (
          <>
            {activityMessage}
            <Meta>{data.event.meta}</Meta>
          </>
        )}
      </ItemText>
    </ListItem>
  );
};
