import { useIntl } from 'react-intl';
import { type IPageItem, type Translation } from '../../../../common/interfaces';
import { Img } from './Image.styled';

export const Image = (props: { item: IPageItem; translations: Translation[] | undefined }) => {
  const { item, translations } = props;
  const { settings } = item;
  const intl = useIntl();

  const handleCLick = () => {
    const config = settings?.buttonConfig;
    if (config?.display !== 'none') {
      let url = '';
      let categoryId = '';

      switch (config?.display) {
        case 'submitIdea':
          categoryId = config?.category.replace(/[^0-9]+/g, '');
          url = '/category';
          break;
        case 'challenges':
          url = '/category/browse';
          break;
        case 'custom':
          url = config?.externalUrl;
          break;
        default:
          url = config?.url;
      }

      window.location.href = categoryId ? `${url}/${categoryId}` : url;
    }
  };

  const handleToolTip = (displayType: string): string => {
    const toolTipMap = {
      submitIdea: intl.formatMessage({ id: 'page.postAnIdea', defaultMessage: 'Post an idea' }),
      challenges: intl.formatMessage({ id: 'page.viewChallenges', defaultMessage: 'View Challenges' }),
      custom: translations?.[0]?.value || settings?.buttonConfig?.text
    };

    return toolTipMap[displayType as keyof typeof toolTipMap];
  };

  return (
    <div data-testid="imageComponent">
      <Img
        alt={settings?.imageAltText}
        src={settings?.imageUrl}
        onClick={() => handleCLick()}
        title={handleToolTip(settings?.buttonConfig?.display)}
        $settings={settings}
      />
    </div>
  );
};
