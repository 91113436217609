import { css } from 'styled-components';
import { typeStyle, units } from '@m/alchemy-ui';

export const CrowdicityGlobalStyle = css`
  body {
    ${typeStyle('bodyS')};
  }

  .text-field {
    b,
    strong {
      font-weight: 700;
    }

    em {
      font-style: italic;
    }

    p {
      margin-bottom: ${units(2)};
    }

    ol,
    ul {
      margin-left: ${units(2.5)};
      margin-bottom: ${units(2.5)};
    }

    ol {
      list-style: decimal;
    }

    ul {
      list-style: disc;
    }

    li {
      line-height: ${units(3)};
    }
  }

  .main_content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: ${units(2.5)};
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    line-height: 1;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 14px;
  }
`;
