import styled from 'styled-components';

const commonCardStyles = (panelSize: number) => `
  display: flex;
  justify-content: space-between;
  word-break: break-word;
  ${panelSize > 1 ? `height: 460px;` : 'height: 520px;'}
`;

export const BlogContainer = styled.div<{ $panelSize: number }>`
  ${(props) => commonCardStyles(props.$panelSize)}

  > div {
    width: 100%;
    max-width: 100%;
  }

  > div + div {
    margin-left: 10px;
    display: flex;
    flex-flow: column;
  }

  > .card2 {
    max-width: calc(33.33% - 10px);
  }
`;

export const BlogListContainer = styled.div``;
export const BlogCard = styled.div<{ $panelSize: number }>`
  ${(props) => commonCardStyles(props.$panelSize)}
`;
