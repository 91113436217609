import styled, { css } from 'styled-components';
import { color, PopperBox, snap, units } from '@m/alchemy-ui';

export const StyledDiv = styled.div`
  margin-top: ${units(4)};
`;

export const PhaseInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const PhaseInfoIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${units(1)};
  svg {
    color: ${color('base')};
  }
`;

export const WarningIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${units(0.5)};
  svg {
    color: ${color('base', { palette: 'interactiveDestructive' })};
  }
`;

export const WarningLabel = styled.div<{ leftMargin: boolean }>`
  color: ${color('base', { palette: 'interactiveDestructive' })};
  ${(props) =>
    props.leftMargin
      ? css`
          padding-left: ${units(3)};
        `
      : ''}
`;

export const CountLabel = styled.div`
  margin-right: ${units(0.5)};
  &:after {
    content: ':';
  }
`;

export const Count = styled.div`
  &:not(:last-of-type):after {
    content: ',';
    margin-right: ${units(0.5)};
  }
`;

export const PhaseFormPopper = styled(PopperBox)`
  width: ${snap(800)};

  @media all and (max-width: 1020px) {
    width: 85%;
  }
`;
