import { Link } from '@m/alchemy-ui';
import styled from 'styled-components';

export const CardHeaderImage = styled.img`
  display: block;
  width: 100%;
`;

export const CardHeader = styled.div`
  height: 25%;
  display: flex;
`;

export const CardUser = styled.div`
  position: relative;
  margin-top: -26px;
  text-align: center;
  z-index: 2;
  font-size: 12px;
`;

export const CardUserAvatar = styled.img`
  display: inline-block;
  width: 52px;
  max-width: 52px;
  margin: 0 auto;
  border: 3px solid #fff;
  border-radius: 50%;
`;

export const CardBody = styled.div`
  height: 100%;
  padding: 0 40px;
  text-align: center;
  overflow-wrap: break-word;
  border: 1px solid #f4f4f4;
  border-top: 0;
  border-bottom: 0;
`;

export const CardBodyTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #222;
  margin: 10px 0;
  padding: 0;
  text-align: center;
`;

export const CardBodyDescription = styled.div`
  text-align: center;
`;

export const CardFooter = styled.footer`
  margin-top: auto;
  text-align: center;
  padding: 10px 0;
  width: 100%;
  max-width: 100%;
  border: 1px solid #f4f4f4;
  border-top: 0;
  box-sizing: border-box;
`;

export const CardFooterLink = styled.a`
  display: block;
  font-weight: 500;
  margin-bottom: 15px;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2px;
`;

export const UserName = styled(Link)`
  margin-right: 4px;
  margin-left: 4px;
`;
