import { Button, Dialog, DialogBody, Field, Input, units } from '@m/alchemy-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Form, Formik, useFormikContext } from 'formik';
import { ButtonRow } from '../../../common/components';
import { type IChallenge } from '../../../common/interfaces';
import { type Theme } from '../../../common/core-api';

interface ThemeFormProps {
  readonly isNew: boolean;
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly theme: Theme;
}

const StyledField = styled(Field)`
  margin-bottom: ${units(2)};
`;

export const ThemeForm = ({ isNew, isOpen, onClose, theme }: ThemeFormProps) => {
  const { values: challenge, setFieldValue } = useFormikContext<IChallenge>();
  const intl = useIntl();

  const handleSubmit = (theme: Theme) => {
    const modifiedThemeIndex = challenge.themes.findIndex((existingTheme) => existingTheme.id === theme.id);
    if (modifiedThemeIndex < 0) {
      setFieldValue(`themes`, [...challenge.themes, theme]);
    } else {
      setFieldValue(`themes[${modifiedThemeIndex}]`, theme);
    }

    onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      hasCloseIcon
      size="medium"
      headerContent={
        isNew
          ? intl.formatMessage({ id: 'createTheme', defaultMessage: 'Create Theme' })
          : intl.formatMessage({ id: 'editTheme', defaultMessage: 'Edit Theme' })
      }
    >
      <DialogBody>
        <Formik
          initialValues={{
            ...theme
          }}
          onSubmit={(value) => handleSubmit(value)}
        >
          {(props) => (
            <Form>
              <StyledField
                label={intl.formatMessage({ id: 'name', defaultMessage: 'Name' })}
                required
                isFullWidth
                input={<Input name="name" maxLength={60} value={props.values.name} onChange={props.handleChange} />}
              />
              <StyledField
                label={intl.formatMessage({ id: 'shortDescriptionLower', defaultMessage: 'Short description' })}
                isFullWidth
                input={
                  <Input
                    name="description"
                    maxLength={140}
                    value={props.values.description}
                    onChange={props.handleChange}
                  />
                }
                helpText={intl.formatMessage({
                  id: 'themeShortDescriptionHelpText',
                  defaultMessage:
                    '140 characters maximum. This will appear in the left hand navigation and on the submission form.'
                })}
              />
              <ButtonRow>
                <Button onClick={onClose} priority="secondary">
                  <FormattedMessage id="cancel" defaultMessage="Cancel" />
                </Button>
                <Button type="submit" priority="primary">
                  <FormattedMessage id="save" defaultMessage="Save" />
                </Button>
              </ButtonRow>
            </Form>
          )}
        </Formik>
      </DialogBody>
    </Dialog>
  );
};
