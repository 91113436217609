import styled from 'styled-components';

interface IdeasStarProps {
  count: number;
  starType: string;
}

export const StarItem = styled.li`
  width: 26%;
  line-height: 12px;
  font-size: 15px;
  margin-right: 2px;
`;

export const IdeasStar = ({ count, starType }: IdeasStarProps) => (
  <StarItem key={count}>
    <span className="mdl-icon -s cwd-core-voting__stars-star">
      <svg>
        <use data-testid="star-icon" href={`#star${starType}`} />
      </svg>
    </span>
  </StarItem>
);
