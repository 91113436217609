import styled from 'styled-components';
import {
  GridCol as AlchemyCol,
  Grid as AlchemyGrid,
  Pagination as AlchemyPagination,
  GridRow as AlchemyRow,
  Table as AlchemyTable,
  snap,
  units
} from '@m/alchemy-ui';

export const Grid = styled(AlchemyGrid)`
  margin-left: ${snap(25)};
`;

export const GridHeader = styled(AlchemyRow)`
  margin-top: ${units(3)};
  margin-bottom: ${units(1.5)};
`;

export const GridRow = styled(AlchemyRow)`
  margin-top: ${snap(10)};
  margin-bottom: ${snap(15)};
`;

export const GridCol = styled(AlchemyCol)`
  padding-left: ${snap(4)} !important;
  padding-right: ${snap(4)} !important;
`;

export const Pagination = styled(AlchemyPagination)`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${units(1)};
`;

export const Table = styled(AlchemyTable)`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;
