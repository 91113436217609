import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Outlet, ReactLocation, Router } from 'react-location';
import { useIntl } from 'react-intl';
import { ga, initialize, set } from 'react-ga';
import { fetchUrl } from '../helpers';
import { Wrapper } from '../common/wrapper';
import { type AppProps, type RouteLink } from '../common/interfaces';
import { Profile } from '../features/profile/Profile';
import { People } from '../features/people/People';
import { Leaderboard } from '../features/leaderboard/Leaderboard';
import { Navigation } from '../features/navigation/Navigation';
import { useSetTitle } from '../hooks';
import { CommunityPage } from '../features/community-page';
import { CommunityContext } from '../context';
import * as Styled from './Application.styled';
import { StaticPage } from './StaticPage';
import { PageHeader } from './PageHeader';
import { Announcements } from './Announcements';
import { EmailValidation } from './EmailValidation';

const Page = (props: { readonly route?: Partial<RouteLink> }) => {
  const Community = useContext(CommunityContext);

  const isReactCustomPage = Community?.options?.beta_renderCommunityPageWithinReactContainer ?? false;

  const { route: { type, banner, title, pageIndex = '' } = {} } = props;
  const setTitle = useSetTitle();

  const event = new CustomEvent('location', {
    detail: {
      location: window.location.href
    }
  });

  window.dispatchEvent(event);

  useEffect(() => {
    // if there's no title we derive it later via async loaded content
    if (!title) {
      return;
    }

    setTitle(title);
  }, [title, setTitle]);

  // removed since isn't present on first load
  document.querySelector('#initial-page-frame')?.remove();

  switch (type) {
    case 'people':
      return (
        <>
          <PageHeader pageBanner={banner} />
          {title && <Styled.PageTitle>{title}</Styled.PageTitle>}
          <People />
        </>
      );
    case 'profile':
      return <Profile />;
    case 'leaderboard':
      return (
        <>
          <PageHeader pageBanner={banner} />
          <Leaderboard />
        </>
      );
    case 'community_page':
      return (
        <>
          <PageHeader pageBanner={banner} />
          {isReactCustomPage && <CommunityPage pageId={pageIndex} />}
          {!isReactCustomPage && <StaticPage />}
        </>
      );
    default:
      return (
        <>
          <PageHeader pageBanner={banner} />
          <StaticPage />
        </>
      );
  }
};

const loaderWithHref = async (url: string = window.location.href) => {
  if (!url.includes('/category')) {
    window.AJAX_ROUTER_URL = undefined;
  }

  const urlContent = await fetchUrl(url);
  const pageContent = new DOMParser().parseFromString(urlContent, 'text/html');
  return {
    pageContent
  };
};

export const location = new ReactLocation();

export const getBlogBanner = (links: RouteLink[]): string => {
  const blogPage = links?.find(({ type }) => type === 'blog');

  return blogPage?.banner || '';
};

export const CrowdicityApplication = ({
  navigation: links,
  gaMeasurementID,
  regionDbCode,
  navigationAdmin: adminLinks
}: {
  readonly navigation: RouteLink[];
  readonly gaMeasurementID: string | undefined;
  readonly regionDbCode: string;
  readonly navigationAdmin: RouteLink[];
}) => {
  const intl = useIntl();
  const navigationRef = useRef<HTMLHeadElement | null>(null);
  const [navHeight, setNavHeight] = useState(0);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const PERCENT = 0.25; // 25%
  const isSticky = windowHeight * PERCENT > navHeight;

  useEffect(() => {
    if (!gaMeasurementID) {
      return;
    }

    initialize(gaMeasurementID, {
      testMode: process.env.NODE_ENV === 'test'
    });
    set({ dimension1: regionDbCode });
    const unlisten = location.history.listen((a) => {
      ga('send', 'pageview', a.location.pathname);
    });

    return () => unlisten();
    // we only want to run this once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (!navigationRef?.current) {
      return;
    }

    setNavHeight(navigationRef.current?.clientHeight);
  }, [windowHeight, setNavHeight]);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    // returned function will be called on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const blogBanner = getBlogBanner(links);

  const routes = [
    // Generated via Crowdicity Routes CMS
    ...links.map((route, index: number) => {
      const { url, type } = route;
      return {
        path: index === 0 ? '/' : url,
        // if we are loading a react app page we don't need to load the href
        loader: ['people'].includes(type) ? undefined : async () => loaderWithHref(url),
        element: <Page route={route} />
      };
    }),

    ...adminLinks.map(({ url: path }) => ({
      path,
      element: null
    })),

    {
      path: 'category/admin_view_crowdicity_one',
      element: null
    },
    {
      path: 'page/admin_edit',
      children: [
        {
          path: '*',
          element: null
        }
      ]
    },
    {
      path: 'badge',
      children: [
        {
          path: '*',
          element: null
        }
      ]
    },
    {
      path: 'survey/admin_view',
      element: null
    },

    // Hard-coded Routes
    {
      path: 'user',
      children: [
        {
          path: 'ideastream',
          element: (
            <Page
              route={{ type: 'profile', title: intl.formatMessage({ id: 'My profile', defaultMessage: 'My Profile' }) }}
            />
          )
        },
        {
          path: 'admin_inviteemails',
          element: null
        },
        {
          path: '*',
          element: (
            <Page
              route={{ type: 'profile', title: intl.formatMessage({ id: 'My profile', defaultMessage: 'My Profile' }) }}
            />
          )
        }
      ]
    },
    {
      path: 'post',
      children: [
        {
          path: ':id',
          loader: async () => loaderWithHref(),
          element: <Page route={{ type: 'post' }} />
        }
      ]
    },
    {
      path: 'category',
      children: [
        {
          path: ':id',
          loader: async () => loaderWithHref(),
          element: <Page />
        }
      ]
    },
    {
      path: 'hubbub',
      children: [
        {
          path: 'leaderboard',
          element: (
            <Page
              route={{
                type: 'leaderboard',
                title: intl.formatMessage({ id: 'Leaderboard', defaultMessage: 'Leaderboard' })
              }}
            />
          )
        }
      ]
    },
    {
      path: 'blog/post/:id',
      loader: async () => loaderWithHref(),
      element: <Page route={{ banner: blogBanner }} />
    },
    {
      path: 'blogpost/:id',
      loader: async () => loaderWithHref(),
      element: <Page route={{ banner: blogBanner }} />
    },
    {
      path: 'blogcategory/:id',
      loader: async () => loaderWithHref(),
      element: <Page route={{ banner: blogBanner }} />
    },
    {
      path: 'dashboard',
      element: null
    },
    {
      path: '*',
      loader: async () => loaderWithHref(),
      element: <Page />
    }
  ];
  const visibleLinks = links.filter((route) => !route.isExcluded);
  return (
    <Router location={location} routes={routes} defaultLoaderMaxAge={60 * 1000}>
      <Navigation links={visibleLinks} isSticky={isSticky} ref={navigationRef} adminLinks={adminLinks} />
      <Styled.OutletWrapper id="application-page-body-content" isSticky={isSticky}>
        <EmailValidation />
        <Announcements />
        <Outlet />
      </Styled.OutletWrapper>
    </Router>
  );
};

export const Application = (props: AppProps) => (
  <Wrapper {...props}>
    <CrowdicityApplication
      navigation={props.navigation || ([] as RouteLink[])}
      navigationAdmin={props.navigationAdmin || ([] as RouteLink[])}
      gaMeasurementID={props.gaMeasurementID}
      regionDbCode={props.regionDbCode}
    />
  </Wrapper>
);
