import styled from 'styled-components';
import { type SubscriptionPanelProps } from '../../features';
import { SubscribeButton } from './components/SubscribeButton/SubscribeButton';

export const SubscriptionPanel = (props: SubscriptionPanelProps) => {
  const { data, isModeratorSubscribe, challengeId } = props;
  const { themesData, moderatorThemesData } = data;

  return (
    <div>
      {isModeratorSubscribe ? (
        <SubscribeButton data={moderatorThemesData} isModeratorSubscribe challengeId={challengeId} />
      ) : null}
      <SubscribeButtonStyle>
        <SubscribeButton data={themesData} isModeratorSubscribe={false} challengeId={challengeId} />
      </SubscribeButtonStyle>
    </div>
  );
};

const SubscribeButtonStyle = styled.span`
  margin-left: 10px;
`;
