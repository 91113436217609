import { gql } from '@apollo/client';

export const GET_PAGE_BY_ID = gql`
  query page($id: Int!, $language: String!) {
    page(id: $id) {
      __typename
      id
      title
      text
      type
      isHidden
      pageNavTitle
      settings
      translations(language: $language) {
        id
        objectId
        field
        iso
        value
      }
      blog {
        posted
        title
        description
        linkUrl
        imageUrl
        imageThumbUrl
        imageLargeUrl
        commentCount
        author {
          id
          name
          avatarUrl
        }
      }
      challengeCarousel(language: $language) {
        panels {
          id
          items {
            id
            title
            subtitle
            imageUrl
            ideaCount
            linkUrl
          }
        }
      }
      activity {
        user {
          id
          name
          avatarUrl
          linkUrl
        }
        event {
          id
          source
          sourceUrl
          action
          targetUrl
          target
          categoryUrl
          category
          meta
        }
      }
      leaderboard {
        leaderboard {
          user {
            id
            name
            avatarUrl
            linkUrl
          }
          event {
            target
            points
          }
        }
        newbies {
          user {
            id
            name
            avatarUrl
            linkUrl
          }
          event {
            target
            points
          }
        }
      }
      ideas {
        latest {
          voting {
            scheme
            value
            stars
          }
          source
          sourceUrl
          target
          targetUrl
        }
        commented {
          voting {
            scheme
            value
            stars
          }
          source
          sourceUrl
          target
          targetUrl
        }
        trend {
          voting {
            scheme
            value
            stars
          }
          source
          sourceUrl
          target
          targetUrl
        }
        random {
          voting {
            scheme
            value
            stars
          }
          source
          sourceUrl
          target
          targetUrl
        }
        voted {
          id
          title
        }
      }
    }
  }
`;

export const GET_IDEAS_BY_CHALLENGE_ID = gql`
  query pageIdeas($id: Int!) {
    pageIdeas(challengeId: $id) {
      __typename
      sourceUrl
      source
      targetUrl
      target
      voting {
        scheme
        value
        stars
      }
    }
  }
`;
