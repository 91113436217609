import { FormattedMessage } from 'react-intl';
import { type IPageIdeaItem } from '../../../../../common/interfaces';
import { ItemText, ListItem, Meta } from '../common/styles';
import { useRuntimeCommunity } from '../../../../../context';
import { Bubble, BubbleText, StarsList } from './IdeaItem.styled';
import { IdeasStar } from './IdeaStar';

interface IIdeaItemProps {
  data: IPageIdeaItem;
  panelSize: number;
  hasWideSibling: boolean;
}

export const IdeaItem = ({ data, panelSize, hasWideSibling }: IIdeaItemProps) => {
  const { voting } = data;
  const { colors } = useRuntimeCommunity();
  const createLink = (name: string, url: string) => (
    <a href={url} title={name}>
      <span>{name}</span>
    </a>
  );

  const postElement = createLink(data.source, data.sourceUrl);
  const categoryElement = createLink(data.target, data.targetUrl);

  const getVotingText = (scheme: string, value: number) => {
    let text: string | JSX.Element = '';

    if (scheme === 'scorecard') {
      text = <FormattedMessage id="page.average" defaultMessage="AVERAGE" />;
    } else if (scheme === 'pointsAward' || scheme === 'updown') {
      text = (
        <FormattedMessage
          id="page.points"
          defaultMessage="{value, plural, one {POINT} other {POINTS}}"
          values={{ value }}
        />
      );
    } else if (scheme !== 'stars') {
      text = (
        <FormattedMessage
          id="page.votes"
          defaultMessage="{value, plural, one {VOTE} other {VOTES}}"
          values={{ value }}
        />
      );
    }

    return text;
  };

  const getStarType = (value: number, count: number): string => {
    if (value >= count) {
      return '-fill';
    }
    if (value >= count - 0.5 && value < count) {
      return '-fill-half';
    }
    return '';
  };

  const getStars = (maxStars: number, value: number): JSX.Element[] => {
    const stars = [];
    for (let count = 1; count <= maxStars; count++) {
      const starType = getStarType(value, count);
      stars.push(<IdeasStar key={count} count={count} starType={starType} />);
    }
    return stars;
  };

  const bubbleValue =
    voting?.scheme === 'stars' ? <StarsList>{getStars(voting?.stars, voting?.value)}</StarsList> : voting?.value;
  return (
    <ListItem $panelSize={panelSize} $hasWideSibling={hasWideSibling}>
      <Bubble bgColor={colors.voteBar}>
        {bubbleValue} <BubbleText>{getVotingText(voting?.scheme, voting?.value)}</BubbleText>
      </Bubble>
      <ItemText>
        <div>{postElement}</div>
        <Meta>
          <FormattedMessage id="page.postedIn" defaultMessage="Posted in" /> {categoryElement}
        </Meta>
      </ItemText>
    </ListItem>
  );
};
