import {
  AppBar as AlchemyAppBar,
  AppLogo as AlchemyAppLogo,
  AppMenuButtonMobile as AlchemyAppMenuButtonMobile,
  AppNav as AlchemyAppNav,
  IconButton as AlchemyIconButton,
  MenuButton as AlchemyMenuButton,
  SearchIcon as AlchemySearchIcon,
  UserIcon as AlchemyUserIcon,
  atMinWidth,
  borderRadius,
  Button,
  color,
  snap,
  typeStyle,
  units
} from '@m/alchemy-ui';
import styled, { css } from 'styled-components';
import { Breakpoints } from '../../common/enums';

export const ACTIVE_OVERFLOW_LINK_CLASSNAME = 'active-overflow-link-classname';

export const AppNavSeparator = styled.span`
  background: ${color('line')};
  height: ${snap(28)};
  margin: 0 ${units(2)};
  width: 1px;
`;

export const NavProfileImage = styled.img`
  border-radius: 50%;
  height: ${snap(30)};
  margin-right: ${units(1)};
  width: ${snap(30)};
`;

export const UserIcon = styled(AlchemyUserIcon)`
  /* Alchemy is overzealous with targeting SVG styles when nested in a AppNavListItem */
  height: ${snap(30)} !important;
  margin-right: ${units(1)} !important;
  width: ${snap(30)} !important;
`;

/* z-index: 998 because we need to have a smaller number than
ul.b-header__main-menu li (desktop/shared.css ) but enough to
avoid other position conflicts */
export const AppBarWrapper = styled.header<{ isSticky: boolean }>`
  border-bottom: 1px solid ${color('line')};
  left: 0;
  top: 0;
  width: 100%;
  z-index: 998;

  ${(props) =>
    props.isSticky
      ? css`
          position: fixed;
        `
      : css`
          position: static;
        `}
`;

export const AppLogo = styled(AlchemyAppLogo)`
  height: 100%;

  ${atMinWidth(
    Breakpoints.TABLET_MIN,
    `
      margin: 0 ${units(2)} 0 0;
    `
  )};
`;

interface AppNavButtonProps {
  readonly alignLeft?: boolean;
  readonly hide?: boolean;
}

export const AppNavButton = styled(Button)<AppNavButtonProps>`
  &:hover {
    text-decoration: none;
  }

  svg {
    margin-right: ${units(1)};
  }

  ${({ alignLeft }) =>
    alignLeft
      ? css`
          margin-left: ${units(1)};
          margin-right: auto;
        `
      : ''}

  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
`;

export const IconButton = styled(AlchemyIconButton)<{ hide?: boolean }>`
  margin-left: ${units(1.5)};

  svg,
  svg:hover {
    color: ${color('base', { palette: 'interactiveConstructive' })} !important;
  }

  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
`;

export const CommunityLogo = styled.img`
  max-height: ${snap(38)};
  max-width: ${snap(220)};
  border-radius: ${borderRadius()};
`;

export const MenuButton = styled(AlchemyMenuButton)`
  margin-left: ${units(1)};
  margin-right: auto;
`;

export const AppNav = styled(AlchemyAppNav)<{ isAdmin?: boolean }>`
  margin-right: 0 !important;

  ul[data-testid='OverflowCollapse'] > li {
    margin-left: -10px;
  }

  ${atMinWidth(
    Breakpoints.MONITOR_MIN,
    `
      margin-right: ${units(2)};
    `
  )};

  ${(props) =>
    props.isAdmin &&
    css`
      & a {
        color: ${color('typeLink', { palette: 'illustrativeBrand' })};

        &:hover {
          text-decoration: none;
        }

        &:after {
          background-color: ${color('typeLink', { palette: 'illustrativeBrand' })};
        }
      }
    `}
`;

export const AppBar = styled(AlchemyAppBar)`
  > * {
    ${atMinWidth(
      Breakpoints.TABLET_MIN,
      `
        height: auto !important;
      `
    )};
    flex-direction: row;
    max-height: 57px;
  }
`;

export const AppPrimaryBar = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
`;

export const AppMenuButtonMobile = styled(AlchemyAppMenuButtonMobile)`
  line-height: 1;
`;

export const AppMobileButtonLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    ${typeStyle('bodyM')};
  }
`;

export const SearchButton = styled(AlchemySearchIcon)`
  margin-right: ${units(1.5)};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
`;

export const AppSwitcherWrapper = styled(ButtonWrapper)`
  margin-left: ${units(1)};
`;
