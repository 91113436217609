import styled from 'styled-components';
import { Button, color, Popper, PopperBox, snap, units } from '@m/alchemy-ui';
import { type Phase } from '../../../common/core-api';
import { PhaseForm } from '.';

const StyledButton = styled(Button)`
  padding: ${units(0.5)} ${units(1.5)};
  min-height: ${units(3)};
  background-color: ${color('container')};
  border: 2px solid ${color('fill')};
`;

const PhaseFormPopper = styled(PopperBox)`
  width: ${snap(800)};

  @media all and (max-width: 1020px) {
    width: 85%;
  }
`;

export const PhaseAddButton = ({
  message,
  previousPhase,
  afterPhase
}: {
  readonly message: string;
  readonly previousPhase?: Phase;
  readonly afterPhase?: Phase;
}) => (
  <Popper
    placement="bottom"
    shouldCloseOnOutsideInteraction={false}
    trigger={<StyledButton priority="tertiary">{message}</StyledButton>}
    overlay={({ setIsOpen }) => (
      <PhaseFormPopper hasArrow maxWidth={snap(800)}>
        <PhaseForm setPopperOpen={setIsOpen} previousPhase={previousPhase} afterPhase={afterPhase} />
      </PhaseFormPopper>
    )}
  />
);
