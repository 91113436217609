import { type IntlShape, useIntl } from 'react-intl';
import { decode } from 'html-entities';
import { ButtonDisplayMode } from '../../../../common/enums';
import { type IPageItem, type IPageItemSettings, type Translation } from '../../../../common/interfaces';
import { useRuntimeCommunity } from '../../../../context';
import { CommunityPageText, TextButton, TextHtmlButton } from './TextHtml.styled';

/* eslint-disable react/no-danger */
export const TextHtml = (props: { item: IPageItem; translations: Translation[] | undefined }) => {
  const { item, translations } = props;
  const { id, settings } = item;
  const intl = useIntl();
  const textTranslation = translations?.find((t) => t.field.includes(`paneltext--${id}`));
  const buttonTextTranslation = translations?.find((t) => t.field.includes(`buttontext--${id}`));
  const { colors } = useRuntimeCommunity();

  return (
    <>
      <CommunityPageText data-testid="textHtmlComponent">
        <div
          className="text-field"
          dangerouslySetInnerHTML={{
            __html: decode(textTranslation?.value || settings?.text)
          }}
        />
        {settings.buttonConfig.display !== ButtonDisplayMode.NONE && (
          <TextButton data-testid="textHtmlButton">
            {renderButton(settings, buttonTextTranslation, intl, colors?.button)}
          </TextButton>
        )}
      </CommunityPageText>
    </>
  );
};

const renderButton = (
  settings: IPageItemSettings,
  translation: Translation | undefined,
  intl: IntlShape,
  buttonBackgroundColor: string
) => {
  let url = '/category/browse/';
  let buttonText = '';

  if (settings?.buttonConfig.display === ButtonDisplayMode.CHALLENGES) {
    buttonText = intl.formatMessage({ id: 'page.viewChallenges', defaultMessage: 'View Challenges' });
  }

  if (settings?.buttonConfig.display === ButtonDisplayMode.SUBMIT_IDEA) {
    buttonText = intl.formatMessage({ id: 'page.postAnIdea', defaultMessage: 'Post an idea' });
  }

  const display = settings?.buttonConfig?.display;
  const isCustomDisplay = display === ButtonDisplayMode.CUSTOM;

  if (isCustomDisplay || display?.startsWith('category_') || display?.startsWith('page_')) {
    url = isCustomDisplay ? encodeURI(settings?.buttonConfig?.externalUrl) : settings?.buttonConfig?.url;
    buttonText = translation?.value || settings?.buttonConfig?.text;
  }

  return (
    <TextHtmlButton bgColor={buttonBackgroundColor} href={url}>
      {buttonText}
    </TextHtmlButton>
  );
};
