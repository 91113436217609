import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { color, ConfirmCheckmarkIcon, EditIcon, Popper, PopperBox, snap, ThumbsUpIcon, units } from '@m/alchemy-ui';
import { useFormikContext } from 'formik';
import { type IChallenge } from '../../../common/interfaces';
import { type Milestone, MilestoneType } from '../../../common/core-api';
import { RocketIcon } from '../../../common/icons';
import { useChallenge } from '../context/Challenge';
import { MilestoneForm } from '.';

const MilestoneFormPopper = styled(PopperBox)`
  width: ${snap(1000)};
`;

const MilestoneIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color('lineContrast')};
  border-radius: 50%;
  width: ${units(5)};
  height: ${units(5)};
  svg {
    color: ${color('inverse')};
    width: ${units(2.5)};
    height: ${units(2.5)};
  }
  cursor: pointer;
`;

export const MilestoneIndicator = ({
  milestone,
  milestoneIndex
}: {
  readonly milestone: Milestone;
  readonly milestoneIndex: number;
}) => {
  const { type } = milestone;
  const { challenge } = useChallenge();
  const { status } = useFormikContext<IChallenge>();

  const [isOpen, setIsOpen] = useState(false);

  const hasError =
    challenge.focusError?.name?.includes('milestone.milestones') && challenge.focusError?.itemIndex === milestoneIndex;

  const handleMilestoneClick = (value = false) => {
    const popupsArray = document.querySelectorAll('.milestonePopper');
    const body = document.querySelector('#fullContainer') as HTMLElement;

    if (body && value) {
      /**
       * 1000px max default height of popup
       */
      body.style.paddingBottom = '1000px';
    } else if (body && !value && popupsArray.length < 2) {
      body.style.paddingBottom = '0';
    }

    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (hasError && !status?.saving) {
      setIsOpen(hasError);
    }
  }, [hasError, status?.saving]);

  return (
    <Popper
      shouldCloseOnOutsideInteraction={false}
      placement="bottom"
      isOpen={isOpen}
      trigger={
        <MilestoneIndicatorWrapper onClick={() => handleMilestoneClick(true)}>
          {type === MilestoneType.SUBMITTED && <RocketIcon />}
          {type === MilestoneType.APPROVAL && <ConfirmCheckmarkIcon aria-hidden="true" />}
          {type === MilestoneType.VOTE && <ThumbsUpIcon aria-hidden="true" />}
          {type === MilestoneType.REFINE && <EditIcon aria-hidden="true" />}
        </MilestoneIndicatorWrapper>
      }
      overlay={
        <div className="milestonePopper">
          <MilestoneFormPopper hasArrow hasPadding maxWidth={snap(1000)}>
            <MilestoneForm
              setPopperOpen={handleMilestoneClick}
              currentMilestone={milestone}
              currentMilestoneIndex={milestoneIndex}
            />
          </MilestoneFormPopper>
        </div>
      }
    />
  );
};
