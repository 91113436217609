import { createRoot } from 'react-dom/client';
import { ChallengeManagement } from './features/challenge-management/ChallengeManagement';
import { ChallengeProvider } from './features/challenge-management/context/Challenge';
import { Wrapper } from './common/wrapper';
import { type AppProps } from './common/interfaces';
import { Analytics } from './features/analytics/Analytics';
import { ModeratorActionPanel } from './features/moderator-action/ModeratorActionPanel';
import { ScoreReset } from './features/score-reset/ScoreReset';
import { type ScoreResetView } from './features/score-reset/enums/ScoreResetView';
import { UserScore } from './features/user-score/UserScore';
import { type SubscribeData } from './common/interfaces/SubscribeButton';
import { SubscriptionPanel } from './features/subscribe/SubscriptionPanel';

interface ChallengeManagementProps extends AppProps {
  readonly challengeId?: string;
}

export interface AnalyticsProps extends AppProps {
  readonly page: string;
}

export interface ModeratorActionProps extends AppProps {
  readonly postId: string;
  readonly originalLocale?: string;
}

export interface ScoreResetProps extends AppProps {
  readonly scoreResetView: ScoreResetView;
  readonly scoreResetUserId: string;
}

export interface UserScoreProps extends AppProps {
  readonly userScoreId: string;
}

export interface SubscriptionPanelProps extends AppProps {
  readonly data: {
    themesData: SubscribeData;
    moderatorThemesData: SubscribeData;
  };
  readonly isModeratorSubscribe: boolean;
  readonly challengeId: string;
}

declare global {
  interface Window {
    COMPONENTS: {
      challengeManagementApp: (props: ChallengeManagementProps) => void;
      analyticsApp: (props: AnalyticsProps, targetId: string) => void;
      scoreResetApp: (props: ScoreResetProps, targetId: string) => void;
      userScoreApp: (props: UserScoreProps, targetId: string) => void;
      moderatorActionApp: (props: ModeratorActionProps, targetId: string) => void;
      subscriptionPanelApp: (props: SubscriptionPanelProps, targetId: string) => void;
    };
  }
}

export const ChallengeManagementApp = (props: ChallengeManagementProps) => {
  const challengeId = String(props.challengeId);

  return (
    <Wrapper {...props}>
      <ChallengeProvider id={challengeId}>
        <ChallengeManagement />
      </ChallengeProvider>
    </Wrapper>
  );
};

export const AnalyticsApp = (props: AnalyticsProps) => (
  <Wrapper {...props}>
    <Analytics {...props} />
  </Wrapper>
);

export const ModeratorActionApp = (props: ModeratorActionProps) => (
  <Wrapper {...props}>
    <ModeratorActionPanel {...props} />
  </Wrapper>
);

export const ScoreResetApp = (props: ScoreResetProps) => (
  <Wrapper {...props}>
    <ScoreReset {...props} />
  </Wrapper>
);

export const UserScoreApp = (props: UserScoreProps) => (
  <Wrapper {...props}>
    <UserScore {...props} />
  </Wrapper>
);

export const SubscriptionPanelApp = (props: SubscriptionPanelProps) => (
  <Wrapper {...props}>
    <SubscriptionPanel {...props} />
  </Wrapper>
);

const domNode = document.createElement('div');
document.querySelector('#main')?.appendChild(domNode);
const root = createRoot(domNode);

window.COMPONENTS = {
  challengeManagementApp: (props: ChallengeManagementProps) => {
    root.render(<ChallengeManagementApp {...props} />);
  },
  analyticsApp: (props: AnalyticsProps, targetId) => {
    const container = document.querySelector(`#${targetId}`);
    container && createRoot(container).render(<AnalyticsApp {...props} />);
  },
  scoreResetApp: (props: ScoreResetProps, targetId) => {
    const container = document.querySelector(`#${targetId}`);
    container && createRoot(container).render(<ScoreResetApp {...props} />);
  },
  userScoreApp: (props: UserScoreProps, targetId) => {
    const container = document.querySelector(`#${targetId}`);
    container && createRoot(container).render(<UserScoreApp {...props} />);
  },
  moderatorActionApp: (props: ModeratorActionProps, targetId) => {
    const container = document.querySelector(`#${targetId}`);
    container && createRoot(container).render(<ModeratorActionApp {...props} />);
  },
  subscriptionPanelApp: (props: SubscriptionPanelProps, targetId) => {
    const container = document.querySelector(`#${targetId}`);
    container && createRoot(container).render(<SubscriptionPanelApp {...props} />);
  }
};
