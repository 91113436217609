export enum EventAction {
  VOTE = 'vote',
  POST = 'post',
  COMMENT = 'comment',
  BLOG_POST = 'blogPost',
  VOTE_COMMENT = 'voteComment',
  USER_FOLLOW = 'userFollow',
  POST_FOLLOW = 'postFollow',
  BLOG_POST_FOLLOW = 'blogpostFollow',
  MILESTONE_REACHED = 'milestoneReached',
  MILESTONE_IDEA_REJECTED = 'milestoneIdeaRejected',
  MILESTONE_INCUBATOR_COMPLEATED = 'milestoneIncubatorCompleted',
  USER_BADGE = 'userBadge',
  USER_BADGES = 'userBadges'
}
