import { BlogItem, BlogList } from '../..';
import { type IBlog } from '../../../../common/interfaces';
import { BlogCard, BlogContainer, BlogListContainer } from './Blog.styled';

interface IBlogProps {
  blogDataSlides: IBlog[];
  panelSize: number;
  hasWideSibling: boolean;
}

const LIST_ITEM_CAP = 7;

export const Blog = ({ blogDataSlides, panelSize, hasWideSibling }: IBlogProps) => {
  const slideCount = blogDataSlides.length;
  const displayList = () =>
    (hasWideSibling && panelSize === 2 && slideCount > 1) ||
    (panelSize === 3 && slideCount > 1) ||
    (panelSize === 2 && slideCount > 2) ||
    (panelSize === 1 && slideCount > 3);

  const itemsToDisplay = () => {
    switch (panelSize) {
      case 1:
        return slideCount <= 3 ? slideCount : 2;
      case 2:
        return slideCount === 1 && hasWideSibling ? 1 : hasWideSibling ? 0 : slideCount <= 2 ? slideCount : 1;
      case 3:
        return slideCount === 1 ? slideCount : 0;
      default:
        return 0;
    }
  };

  const listItems = blogDataSlides.slice(-Math.abs(blogDataSlides.length - itemsToDisplay()));

  return (
    <BlogContainer $panelSize={panelSize}>
      {itemsToDisplay() > 0 && (
        <BlogCard $panelSize={panelSize}>
          {blogDataSlides
            ?.slice(0, itemsToDisplay())
            .map((blogItem) => (
              <BlogItem
                key={`${blogItem.posted}-${blogItem.title}`}
                data={blogItem}
                itemsToDisplay={itemsToDisplay()}
                panelSize={panelSize}
              />
            ))}
        </BlogCard>
      )}
      {displayList() && (
        <BlogListContainer data-testid="blog-list" className={`card${itemsToDisplay()}`}>
          <BlogList
            data={panelSize > 1 && listItems.length > LIST_ITEM_CAP ? listItems.slice(0, -panelSize) : listItems}
          />
        </BlogListContainer>
      )}
    </BlogContainer>
  );
};
