import styled from 'styled-components';

export const TabbedPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  height: 100%;
`;

export const ContentContainer = styled.div`
  flex-grow: 1;
  flex-wrap: wrap;
  max-height: 400px;
  overflow-y: auto;
  padding-right: 15px;
  padding-left: 5px;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &:hover {
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
    }
  }
`;

export const Footer = styled.div`
  text-align: center;
`;
