import { gql } from '@apollo/client';

export const GET_COMMUNITY = gql`
  query community($id: String!) {
    community(id: $id) {
      id
      name
      url
      closed
      logoId
      logoFile {
        id
        name
        url
        hash
        extension
      }
      colors {
        link
        button
        voteBar
        userNavBg
      }
      options {
        translationLanguages
        livingLens {
          enabled
        }
        allowLanguageChoice
        allowedLanguages {
          name
          iso
        }
        showUserScores
        allowSocialMediaLinksInProfile
        anonymousSubmissionsEnabled
        allowSocialMediaLogin
        allowSAML2Login
        allowAzureLogin
        allowCrowdicityLogin
        OpenIdProvider
        allowMessaging
        apiEnabled
        enableSlackIntegration
        enableTeamsIntegration
        twitterName
        twitterAllowFollow
        youtubeName
        youtubeAllowFollow
        facebookName
        facebookAllowFollow
        crowdicityAsIDPForSalesforce
        beta_renderCommunityPageWithinReactContainer
        appSwitcher {
          name
          url
          icon
        }
      }
      defaultAccess {
        users {
          user {
            id
            displayName
            screenName
            createdAt
            commentsCount
            firstName
            lastName
            profileImageId
            timezone
            votesCount
            profileImageFile {
              id
              name
              url
              hash
              extension
              userId
            }
          }
          canModerate
        }
        groups {
          usergroup {
            id
            name
          }
          canModerate
        }
      }
    }
  }
`;

export const GET_COUNTRIES = gql`
  query getCountries($id: String!) {
    community(id: $id) {
      countries
    }
  }
`;
