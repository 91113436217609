import { createContext, type Dispatch, type ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { type ApolloQueryResult, useQuery } from '@apollo/client';
import { useImmerReducer } from 'use-immer';
import { defaultChallenge, type IChallenge } from '../../../common/interfaces';
import { type ChallengeQuery, GET_CHALLENGE } from '../../../graphql/queries';
import { type ChallengeActionType, ChallengeActionTypes, challengeReducer } from '../reducers/challenge';

const ChallengeContext = createContext<{
  challenge: IChallenge;
  setIsNew: (isNew: boolean) => void;
  refetch?: () => Promise<ApolloQueryResult<ChallengeQuery>>;
}>({
  challenge: defaultChallenge,
  setIsNew: () => {}
});
const ChallengeDispatchContext = createContext<Dispatch<ChallengeActionType> | undefined>(undefined);

const ChallengeProvider = ({ children, id }: { readonly children: ReactNode; readonly id?: string }) => {
  const [challenge, dispatch] = useImmerReducer(challengeReducer, defaultChallenge);
  const [isNew, setIsNew] = useState(id === defaultChallenge.id);
  const currentChallengeId = challenge.id === defaultChallenge.id ? id : challenge.id;

  const { data, loading, refetch } = useQuery<ChallengeQuery>(GET_CHALLENGE, {
    variables: { id: currentChallengeId },
    skip: isNew
  });

  useEffect(() => {
    if ((loading === false && data) || isNew) {
      dispatch({
        type: ChallengeActionTypes.SET_CHALLENGE,
        value: isNew
          ? { ...defaultChallenge, isNew, loading: false }
          : { ...(data?.challenge || defaultChallenge), isNew, loading: false }
      });
    }
  }, [isNew, dispatch, loading, data]);

  const contextValue = useMemo(() => ({ challenge, refetch, setIsNew }), [challenge, refetch, setIsNew]);

  return (
    <ChallengeContext.Provider value={contextValue}>
      <ChallengeDispatchContext.Provider value={dispatch}>{children}</ChallengeDispatchContext.Provider>
    </ChallengeContext.Provider>
  );
};

const useChallenge = () => useContext(ChallengeContext);

const useChallengeDispatch = () => {
  const context = useContext(ChallengeDispatchContext);

  if (context === undefined) {
    throw new Error('useChallengeDispatch requires ChallengeProvider');
  }

  return context;
};

export { ChallengeProvider, useChallengeDispatch, useChallenge };
