import styled from 'styled-components';

export const SlideDescription = styled.div<{ bgColor: string }>`
  display: flex;
  border: none;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background: ${({ bgColor }) => `#${bgColor}bb`};
  opacity: 0.8;
  padding: 16px 20px;
  color: #fff;
  justify-content: space-between;

  #carousel-container:hover & {
    background: ${({ bgColor }) => `#${bgColor}`};
  }
`;

export const SlideTitle = styled.h2`
  font-size: 16px;
  line-height: 23px;
  color: white;
  margin: 0 0 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: 16px;
`;

export const SlideSubtitle = styled.p`
  margin-bottom: 0
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SlideLink = styled.a`
  display: block;
  text-decoration: none;
`;

export const SlideImage = styled.aside`
  position: relative;

  img {
    width: 100%;
    display: block;
  }
`;

export const SlideStats = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const SlideStatItem = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 16px;
  white-space: nowrap;
`;

export const Slide = styled.div`
  min-width: 100%;
  box-sizing: border-box;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  position: relative;

  &:focus-within {
    border: 2px solid #1c4ed8;
  }

  img {
    width: 100%;
    max-width: unset;
    display: block;
  }
`;

export const SlideStatIcon = styled.span`
  margin-right: 5px;
  color: white;
  width: 16px;
  height: 16px;
}`;
