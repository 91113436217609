import styled from 'styled-components';
import { type ChangeEvent, useContext, useState } from 'react';
import {
  color,
  DatePicker,
  Field,
  FieldContent,
  FieldDescription,
  FieldLabel,
  Input,
  snap,
  TextArea,
  typeStyle,
  units
} from '@m/alchemy-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { type FormikProps, useFormikContext } from 'formik';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { format } from 'date-fns';
import { type Phase } from '../../../common/core-api';
import { type Duration, getEndDate, getPhaseDuration } from '../helpers/phase';
import { useFieldError } from '../../../hooks';
import { UserContext } from '../../../context';
import { TranslatableInput } from '.';

const LabelWrapper = styled.div`
  width: ${snap(100)};
  align-self: flex-start;
`;
const PhaseDetailsWrapper = styled.div`
  padding: ${units(2)};
`;
const DetailItem = styled.div`
  padding-bottom: ${units(2)};
`;
const StartTimeInput = styled(Input)`
  max-width: ${snap(130)};
  width: ${snap(130)};
  display: inline-flex;
  margin-left: ${units(1)};
  input {
    min-width: 0;
  }
`;
const StartDateAndTime = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${units(0.5)};
`;
const DurationInput = styled(Input)`
  width: ${units(7)} !important;
  display: inline-flex;
  input {
    min-width: 0;
  }
`;
const StyledDatePicker = styled(DatePicker)`
  display: inline-flex;
  min-width: ${snap(130)};
  width: ${snap(130)};
  margin-right: ${units(1)};
  input {
    width: ${snap(130)};
  }
`;
const HelpText = styled.div`
  ${typeStyle('captionM')};
  color: ${color('typeSubHeader')};
`;

export const PhaseDetails = ({
  isNewPhase,
  isFirstPhase,
  isLastPhase,
  startDateChangeDisabled
}: {
  readonly isNewPhase: boolean;
  readonly isFirstPhase: boolean;
  readonly isLastPhase: boolean;
  readonly startDateChangeDisabled: boolean;
}) => {
  const intl = useIntl();
  const user = useContext(UserContext);

  const { values, handleChange, setFieldValue, handleBlur }: FormikProps<Phase> = useFormikContext();
  const { id, name, description, startDate, endDate } = values;

  const [fieldError] = useFieldError();

  const [duration, setDuration] = useState<Duration>(getPhaseDuration(startDate as string, endDate as string));
  const startDateInUserTimezone = utcToZonedTime(startDate as string, user?.timezone || 'UTC');
  const hourInTimezone = startDateInUserTimezone.getHours();
  const minuteInTimezone = startDateInUserTimezone.getMinutes();
  const startTimeHour = hourInTimezone < 10 ? '0' + String(hourInTimezone) : String(hourInTimezone);
  const startTimeMinute = minuteInTimezone < 10 ? '0' + String(minuteInTimezone) : String(minuteInTimezone);
  const startTimeInUserTimezone = startTimeHour + ':' + startTimeMinute;

  const handleStartDateChange = (value: string) => {
    const monthDateYear = value.split('/');
    startDateInUserTimezone.setFullYear(
      Number(monthDateYear[2]),
      Number(monthDateYear[0]) - 1,
      Number(monthDateYear[1])
    );
    const newStartDate = zonedTimeToUtc(startDateInUserTimezone, user?.timezone || 'UTC');
    setFieldValue('startDate', newStartDate.toISOString());
    if (!isLastPhase) {
      setFieldValue('endDate', getEndDate(newStartDate.toISOString(), duration));
    }
  };

  const handleStartTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const hourAndMinute = e.target.value.split(':');
    startDateInUserTimezone.setHours(Number(hourAndMinute[0]));
    startDateInUserTimezone.setMinutes(Number(hourAndMinute[1]));
    const newStartDate = zonedTimeToUtc(startDateInUserTimezone, user?.timezone || 'UTC');
    setFieldValue('startDate', newStartDate.toISOString());
    if (!isLastPhase) {
      setFieldValue('endDate', getEndDate(newStartDate.toISOString(), duration));
    }
  };

  const handleDurationChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newDuration = { ...duration, [e.target.name]: Number(e.target.value) };
    setDuration(newDuration);
    setFieldValue('endDate', getEndDate(startDate as string, newDuration));
  };

  return (
    <PhaseDetailsWrapper>
      <DetailItem>
        <Field
          layoutVariant="inline"
          isFullWidth
          label={<LabelWrapper>{intl.formatMessage({ id: 'phaseName', defaultMessage: 'Phase Name' })}</LabelWrapper>}
          status={fieldError('name')}
          input={
            <TranslatableInput type="Phase" field="name" translateId={id}>
              <Input name="name" value={name || ''} onChange={handleChange} onBlur={handleBlur} />
            </TranslatableInput>
          }
        />
      </DetailItem>
      {(isFirstPhase || isNewPhase) && (
        <DetailItem>
          <Field layoutVariant="inline">
            <FieldLabel required htmlFor="phaseStartDate">
              <LabelWrapper>{intl.formatMessage({ id: 'start', defaultMessage: 'Start' })}</LabelWrapper>
            </FieldLabel>
            <FieldContent>
              <StartDateAndTime>
                <FormattedMessage
                  id="phaseStartDate"
                  defaultMessage="{dateInput} at {timeInput}"
                  values={{
                    dateInput: (
                      <StyledDatePicker
                        date={startDateInUserTimezone}
                        onDateChange={(date) => {
                          if (date) {
                            handleStartDateChange(format(date, 'MM/dd/yyyy'));
                          }
                        }}
                        shouldValidateOnBlur
                        disabled={startDateChangeDisabled}
                      />
                    ),
                    timeInput: (
                      <StartTimeInput
                        type="time"
                        isFullWidth
                        value={startTimeInUserTimezone}
                        onChange={(e) => handleStartTimeChange(e)}
                        disabled={startDateChangeDisabled}
                      />
                    )
                  }}
                />
              </StartDateAndTime>
              <FieldDescription>
                <HelpText>
                  {intl.formatMessage({
                    id: 'phaseStartDateHelpText',
                    defaultMessage:
                      'The date and time that this phase begins. This is based on the end time of the previous phase.'
                  })}
                </HelpText>
              </FieldDescription>
            </FieldContent>
          </Field>
        </DetailItem>
      )}
      <DetailItem>
        {isLastPhase ? (
          <Field
            layoutVariant="inline"
            isFullWidth
            label={<LabelWrapper>{intl.formatMessage({ id: 'duration', defaultMessage: 'Duration' })}</LabelWrapper>}
            required
            input={
              <HelpText>
                {intl.formatMessage({
                  id: 'lastPhaseDurationHelpText',
                  defaultMessage: 'Last phase continues indefinitely (unless another is added after it)'
                })}
              </HelpText>
            }
          />
        ) : (
          <Field layoutVariant="inline">
            <FieldLabel required htmlFor="phaseDuration">
              <LabelWrapper>{intl.formatMessage({ id: 'duration', defaultMessage: 'Duration' })}</LabelWrapper>
            </FieldLabel>
            <FieldContent>
              <FormattedMessage
                id="phaseDuration"
                defaultMessage="{dayInput} {day, plural, one {day} other {days}}, {hourInput} {hour, plural, one {hour} other {hours}}, {minuteInput} {minute, plural, one {minute} other {minutes}}"
                values={{
                  dayInput: (
                    <DurationInput
                      name="day"
                      isFullWidth
                      value={duration.day}
                      onChange={(e) => handleDurationChange(e)}
                    />
                  ),
                  day: duration.day,
                  hourInput: (
                    <DurationInput
                      name="hour"
                      isFullWidth
                      value={duration.hour}
                      onChange={(e) => handleDurationChange(e)}
                    />
                  ),
                  hour: duration.hour,
                  minuteInput: (
                    <DurationInput
                      name="minute"
                      isFullWidth
                      value={duration.minute}
                      onChange={(e) => handleDurationChange(e)}
                    />
                  ),
                  minute: duration.minute
                }}
              />
              <FieldDescription>
                <HelpText>
                  {intl.formatMessage({
                    id: 'phaseDurationHelpText',
                    defaultMessage: 'The amount of time the phase will run for.'
                  })}
                </HelpText>
              </FieldDescription>
            </FieldContent>
          </Field>
        )}
      </DetailItem>
      <DetailItem>
        <Field
          layoutVariant="inline"
          isFullWidth
          label={
            <LabelWrapper>{intl.formatMessage({ id: 'description', defaultMessage: 'Description' })}</LabelWrapper>
          }
          input={
            <TranslatableInput type="Phase" field="description" translateId={id}>
              <TextArea name="description" value={description || ''} onChange={handleChange} minHeight={snap(150)} />
            </TranslatableInput>
          }
          description={
            <HelpText>
              {intl.formatMessage({
                id: 'phaseDescriptionHelpText',
                defaultMessage: 'You can supply additional information about the aims of this phase'
              })}
            </HelpText>
          }
        />
      </DetailItem>
    </PhaseDetailsWrapper>
  );
};
