import { gql } from '@apollo/client';

export const translationFragment = gql`
  fragment TranslationData on Translation {
    id
    communityId
    field
    iso
    objectType
    objectId
    value
  }
`;

export const challengeFragment = gql`
  fragment ChallengeWithData on Challenge {
    id
    archived
    active
    communityId
    config {
      showInCarousels
      sorting {
        options
        default
      }
      themeRequired
      ideaDescriptionCharacterLimit
      hideUploadFiles
      textThemeName
      ideaDescriptionType
    }
    headings {
      ideaDescriptionHeading
      ideaDescriptionInfoHeading
      ideaFilesHeading
      ideaTitleHeading
      ideaTitleInfoHeading
      themeSelectHeading
    }
    title
    titleUnique
    subtitle
    description
    longDescription
    isFunnel
    isMilestone
    ideasHaveVotes
    ideas {
      id
      challengeId
      communityId
      userId
    }
    translations {
      ...TranslationData
    }
    access {
      users {
        user {
          id
          displayName
          screenName
          createdAt
          commentsCount
          firstName
          lastName
          profileImageId
          timezone
          votesCount
          profileImageFile {
            id
            name
            url
            hash
            extension
            userId
          }
        }
        canModerate
      }
      groups {
        usergroup {
          id
          name
        }
        canModerate
      }
      options {
        comments {
          enabled
          attachmentsEnabled
          anonymousEnabled
        }
        ideaSubmissionEnabled
        ideasVisible
        ideaSubmissionsType
        showClosedEnabled
        voteOnCommentsEnabled
        voteOnIdeasEnabled
        privateSubmissionEnabled
        ideaEditLock {
          enabled
          graceMinutes
        }
        shareFacebookEnabled
        shareTwitterEnabled
        shareLinkedInEnabled
        copyUrlEnabled
      }
    }
    categories {
      id
      order
      name
      assigned
    }
    files {
      header {
        id
        name
        url
        hash
        extension
        userId
      }
      list {
        id
        name
        url
        hash
        extension
        userId
      }
      attachments {
        id
        name
        url
        hash
        extension
        userId
      }
    }
    options
    questions {
      id
      characterLimit
      description
      name
      hidden
      required
      order
      type
      inPhase
      milestoneId
      translations {
        ...TranslationData
      }
      options {
        id
        text
        translations {
          ...TranslationData
        }
      }
    }
    voting {
      isBlind
      showVoters
      type
      wallet {
        isEnabled
        allowance
      }
      scorecards {
        cards {
          id
          name
          description
          order
          phaseId
          milestoneId
          translations {
            ...TranslationData
          }
        }
        labels {
          id
          name
          rating
          milestoneId
          translations {
            ...TranslationData
          }
        }
      }
      options {
        maxStars
        maxScore
        minimumRatings
      }
    }
    themes {
      id
      name
      order
      description
      archived
      communityId
      createdAt
    }
    phases {
      id
      name
      description
      startDate
      endDate
      access {
        users {
          user {
            id
            displayName
            screenName
            createdAt
            commentsCount
            firstName
            lastName
            profileImageId
            timezone
            votesCount
            profileImageFile {
              id
              name
              url
              hash
              extension
              userId
            }
          }
          canModerate
        }
        groups {
          usergroup {
            id
            name
          }
          canModerate
        }
        options {
          comments {
            enabled
            attachmentsEnabled
            anonymousEnabled
          }
          ideaSubmissionEnabled
          ideasVisible
          ideaSubmissionsType
          showClosedEnabled
          voteOnCommentsEnabled
          voteOnIdeasEnabled
          privateSubmissionEnabled
          ideaEditLock {
            enabled
            graceMinutes
          }
          phaseAutoAdvancement
          phaseResetComments
          phaseResetVotes
          shareFacebookEnabled
          shareTwitterEnabled
          shareLinkedInEnabled
          copyUrlEnabled
        }
      }
      questions {
        id
        characterLimit
        description
        name
        hidden
        required
        order
        type
        inPhase
        milestoneId
        translations {
          ...TranslationData
        }
        options {
          id
          text
          translations {
            ...TranslationData
          }
        }
      }
      voting {
        isBlind
        showVoters
        type
        wallet {
          isEnabled
          allowance
        }
        scorecards {
          cards {
            id
            name
            description
            order
            phaseId
            milestoneId
            translations {
              ...TranslationData
            }
          }
          labels {
            id
            name
            rating
            milestoneId
            translations {
              ...TranslationData
            }
          }
        }
        options {
          maxStars
          maxScore
          minimumRatings
        }
      }
      translations {
        ...TranslationData
      }
    }
    milestone {
      completeMessageToAuthors
      completeMessageToUsers
      daysLimit
      timeLimitEnabled
      milestones {
        id
        name
        label
        description
        type
        target
        minimumVotes
        approachMessageToAuthors
        approachMessageToUsers
        approachEmailMessageToVoters
        reachedEmailMessageToAuthors
        approachEmailMessageToModerators
        ideasHaveVotes
        access {
          allowComments
          privateSubmissionEnabled
        }
        condition {
          id
        }
        questions {
          id
          characterLimit
          description
          name
          hidden
          required
          order
          type
          inPhase
          milestoneId
          translations {
            ...TranslationData
          }
          options {
            id
            text
            translations {
              ...TranslationData
            }
          }
        }
        moderators {
          users {
            id
            displayName
            screenName
            createdAt
            commentsCount
            firstName
            lastName
            profileImageId
            timezone
            votesCount
            profileImageFile {
              id
              name
              url
              hash
              extension
              userId
            }
          }
          groups {
            id
            name
          }
        }
        voters {
          users {
            id
            displayName
            screenName
            createdAt
            commentsCount
            firstName
            lastName
            profileImageId
            timezone
            votesCount
            profileImageFile {
              id
              name
              url
              hash
              extension
              userId
            }
          }
          groups {
            id
            name
          }
        }
        voting {
          isBlind
          showVoters
          type
          scorecards {
            cards {
              id
              name
              description
              order
              phaseId
              milestoneId
              translations {
                ...TranslationData
              }
            }
            labels {
              id
              name
              rating
              milestoneId
              translations {
                ...TranslationData
              }
            }
          }
        }
        translations {
          ...TranslationData
        }
      }
    }
  }
  ${translationFragment}
`;

export const challengeFragmentWithFilters = gql`
  fragment ChallengeFragmentWithFilters on Challenge {
    id
    phases {
      id
      name
      description
      startDate
      endDate
      ideas(filters: $filters) {
        id
        title
      }
    }
    milestone {
      milestones {
        id
        name
        label
        description
        ideas(filters: $filters) {
          id
          title
          moderationStatus
        }
      }
      milestoneRejectedIdeaIds
    }
  }
`;

export const GET_CHALLENGES = gql`
  query challenges(
    $order: [String!]
    $publicationStatus: ChallengePublicationStatus
    $types: [String!]
    $includeUserCreated: Boolean
  ) {
    challenges(
      order: $order
      publicationStatus: $publicationStatus
      types: $types
      includeUserCreated: $includeUserCreated
    ) {
      id
      archived
      title
    }
  }
`;

export const GET_CHALLENGE = gql`
  query challenge($id: String!) {
    challenge(id: $id) {
      ...ChallengeWithData
    }
  }
  ${challengeFragment}
`;

export const GET_CHALLENGE_WITH_FILTERS = gql`
  query challengeWithFilters($id: String!, $filters: UserSearchFilters) {
    challenge(id: $id) {
      ...ChallengeFragmentWithFilters
    }
  }
  ${challengeFragmentWithFilters}
`;

export const GET_USER_ACCESSIBLE_CHALLENGES = gql`
  query userAccessibleChallenges($publicationStatus: ChallengePublicationStatus) {
    accessibleChallenges(publicationStatus: $publicationStatus) {
      id
      archived
      title
    }
  }
`;

export const CHALLENGES_FOR_SEARCH_QUERY = gql`
  query getChallengesForSearch {
    challenges {
      id
      title
    }
  }
`;

export const IS_CHALLENGE_EXIST = gql`
  query isChallengeExist($id: String!, $title: String!) {
    challengeExist(id: $id, title: $title)
  }
`;
