export enum CommunityPageType {
  TEXT = 'text',
  IMAGE = 'image',
  CHALLENGE_CAROUSEL = 'challenge-carousel',
  VIDEO = 'video',
  BLOG_CAROUSEL = 'blog-carousel',
  LINE = 'line',
  ACTIVITY = 'activity',
  LEADERBOARD = 'leaderboard',
  IDEAS = 'ideas'
}
